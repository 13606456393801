// EditProfile.js
import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State, City } from "country-state-city";
import backendHost from "../../Api";
import { showToast } from "../../Store/Slices/toastSlice";
import { setSelectedBusiness } from "../../Store/Slices/selectedBusinessSlice";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";

function EditProfile() {
  const navigate = useNavigate();
  const user = useSelector(store => store.user);
  const selectedBusiness = useSelector(store => store.selectedBusiness)

  const [businessName, setBusinessName] = useState(selectedBusiness?.businessName);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState(selectedBusiness?.address);
  const [dynamicCategory, setDynamicCategory] = useState([]);
  const [pin, setPin] = useState(selectedBusiness?.pin);
  const [cityList, setCityList] = useState([]);
  const [dynamicSubcategoryMap, setDynamicSubcategoryMap] = useState({});
  const [category, setCategory] = useState("--Select Category--");
  const [subCategory, setSubCategory] = useState("--Select Sub Category--");
  const [businessLogo, setBusinessLogo] = useState("");
  const [logoPreview, setLogoPreview] = useState(selectedBusiness?.businessLogo || "");
  const [description, setDescription] = useState("")
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?._id) {
      navigate("/login");
    }
    if (selectedBusiness?._id) {
      setCountry(selectedBusiness?.country || "");
      setState(selectedBusiness?.state || "");
      setCity(selectedBusiness?.city || "");
      setCategory(selectedBusiness?.category || "--Select Category--");
      setDescription(selectedBusiness?.description || "Enter Business descriptoin here : ")
      setSubCategory(selectedBusiness?.subCategory || "--Select Sub Category--");
    }
  }, [user, selectedBusiness, navigate]);

  useEffect(() => {
    const fetchStates = () => {
      if (country === "United States") {
        setStates(State.getStatesOfCountry("US").map((e) => e.name));
      } else if (country === "Canada") {
        setStates(State.getStatesOfCountry("CA").map((e) => e.name));
      } else {
        setStates([]);
      }
    };
    fetchStates();
  }, [country]);

  useEffect(() => {
    const fetchCities = () => {
      if (state) {
        const countryCode = country === "Canada" ? "CA" : "US";
        const stateCode = State.getStatesOfCountry(countryCode).find((s) => s.name === state)?.isoCode;
        const cities = City.getCitiesOfState(countryCode, stateCode);
        setCityList(cities.map((c) => c.name));
      }
    };
    fetchCities();
  }, [state, country]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await backendHost.get(`/business/getCategoriesgetSubCategories`);
        const categoryNames = res?.data?.categories.map((item) => item.name);
        setDynamicCategory(categoryNames);
        setDynamicSubcategoryMap(res.data.categorySubcategoryMap);
      } catch (err) {
        console.log(err?.message);
      }
    };
    fetchCategories();
  }, []);
  console.log(selectedBusiness)
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const updatedData = {
        businessName: businessName || selectedBusiness?.businessName,
        address: address || selectedBusiness?.address,
        country: country || selectedBusiness?.country,
        state: state || selectedBusiness?.state,
        city: city || selectedBusiness?.city,
        pin: pin || selectedBusiness?.pin,
        category: category !== "--Select Category--" ? category : selectedBusiness?.category,
        subCategory: subCategory !== "--Select Sub Category--" ? subCategory : selectedBusiness?.subCategory,
        businessLogo,
        description,
      };
      const formdata = new FormData();
      Object.entries(updatedData).forEach(([key, value]) => formdata.append(key, value));
      backendHost.post(`/business/editBusiness/${selectedBusiness?._id}`, formdata)
        .then(res => {
          dispatch(setSelectedBusiness(res?.data?.business));
          dispatch(setBusinessProfile(res?.data?.businessProfile));
          navigate("/businessDashboard");
          dispatch(showToast({ type: 'success', message: res?.data?.message }));
        })
        .catch(err => {
          console.log(err); 
          dispatch(showToast({ type: 'error', message: err?.response?.data?.message || 'An error occurred' }));
        });
    } catch (error) {
      console.log(error); 
      dispatch(showToast({ message: error?.message, type: 'error' }));
    }
  };

  const handleLogoChange = (e) => {
    try {
      const file = e.target.files[0];
      setBusinessLogo(file);
      setLogoPreview(URL.createObjectURL(file));
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <div className="container">
      <div className="ms-5 me-5">
        <Card className="">
          <div className="name-heding">
            <div className="d-flex">
              <Link to="/businessDashboard">
                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
              </Link>
              <h5 className="business-heding">Update Business Details</h5>
            </div>
          </div>
          <div className="conten">
            <span>Enter your Business name as you want it to appear for everyone.</span>
            <span>Enter legal business name as mentioned on your official Business Licence/Pan Card, etc.</span>
            <form className="edit-form p-4 d-flex flex-column row g-3" onSubmit={handleSubmit}>
              <label>Business Name</label>
              <div className="input-group">
                <input
                  className="input--style-1"
                  type="text"
                  placeholder="Business Name"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>
              <label>Business Address</label>
              <div className="row mb-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business Street Address*"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                  >
                    <option disabled>--Select Country--</option>
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <select
                    className="form-select"
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                  >
                    <option disabled>--Select State--</option>
                    {states?.map((state, ind) => (
                      <option key={ind} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  >
                    <option disabled>--Select City--</option>
                    {cityList?.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Postal Code*"
                    onChange={(e) => setPin(e.target.value)}
                    value={pin}
                  />
                </div>
                <div className="col-md-6">
                  <textarea style={{ width: "100%", resize: "none", marginTop: "14px", padding: "5px", boder: "1px solid lightgray", color: `${description === "Enter Business Description here :  " ? "lightgray" : "black"}` }} onClick={() => {
                    if (description === "Enter Business Description here :  ") {
                      setDescription("");
                    }
                  }} rows={1} value={description} onChange={(e) => setDescription(e.target.value)} name="" id=""></textarea>
                </div>
              </div>
              <label>Business Category</label>
              <div className="row mb-3">
                <div className="col-md-6">
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="form-select"
                  >
                    <option disabled>--Select Category--</option>
                    {dynamicCategory?.map((cate) => (
                      <option key={cate} value={cate}>
                        {cate}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    onChange={(e) => setSubCategory(e.target.value)}
                    value={subCategory}
                  >
                    <option disabled>--Select Sub Category--</option>
                    {category &&
                      dynamicSubcategoryMap[category] &&
                      dynamicSubcategoryMap[category].map((subCate) => (
                        <option key={subCate} value={subCate}>
                          {subCate}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {logoPreview && (
                <div className="mb-3" style={{ height: "150px", width: "150px" }}>
                  <label>Business Logo</label>
                  <img
                    src={logoPreview}
                    alt="Business Logo"
                    style={{ width: "100%", height: "auto", objectFit: "contain", objectPosition: "center" }}
                  />
                </div>
              )}
              <input
                required={!selectedBusiness?.businessLogo}
                className="form-control"
                type="file"
                onChange={handleLogoChange}
              />
              <div className="d-flex justify-content-center align-items-center pt-2 pb-2">
                <button type="submit" className="btn btn-warning text-white" style={{ width: "200px" }}>
                  SAVE
                </button>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default EditProfile;
