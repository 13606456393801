import React, { useState } from "react";
import { Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import "./AddOffers.css";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

const AddOffers = () => {
    const businessProfile = useSelector(store => store?.businessProfile);
    const selectedBusiness = useSelector(store => store?.selectedBusiness);
    const [customTitle, setCustomTitle] = useState("");
    const [previousOffer, setPreviousOffer] = useState(businessProfile?.offer?.length > 0 ? businessProfile?.offer[0] : "");
    const [selectedOffer, setSelectedOffer] = useState()
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const offers = [
        "Get Upto 25% Off",
        "Get $5 Discount",
        "Buy 1 Get 1 ",
        "Weekend WOW Offer 50% off",
        "Flat 20% Discount",
    ];

    const handleCustomTitleChange = (e) => {
        setCustomTitle(e.target.value);
    };

    const handleCustomTitleSubmit = () => {
        const percentOfferPattern = /\d+%/;
        const dollarOfferPattern = /\$\d+/;
        if (customTitle && customTitle.trim() && (percentOfferPattern.test(customTitle) || dollarOfferPattern.test(customTitle))) {
            setSelectedOffer(customTitle);
            setShowModal(false);
        } else {
            console.error("Invalid offer format");
            dispatch(showToast({ message: "Please enter a valid offer that includes either a number followed by a percent sign (e.g., '20% off') or a dollar amount prefixed with a dollar sign (e.g., '$100 off').", type: 'warning' })
            );
        }
    };

    const handleSelectOffer = (offer) => {
        setSelectedOffer(offer);
    };

    const handleSubmit = (e) => {
        try {
            e.preventDefault();
            backendHost
                .post(`/businessProfile/addOffers/${selectedBusiness?._id}`, {
                    selectedOffer,
                })
                .then((res) => {
                    dispatch(showToast({ message: res?.data?.message, type: 'success' }));
                    dispatch(setBusinessProfile(res?.data?.businessProfile))
                    navigate("/businessDashboard")
                })
                .catch((err) => {
                    console.log(err?.message)
                    dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }))
                });
        } catch (error) {
            dispatch(showToast({ message: error?.message, type: 'error' }))
        }
    };

    return (
        <div className="add-offers-container">
            <div className="add-offers-content">
                <Card className="add-offers-card">
                    <div className="add-offers-header">
                        <div className="d-flex align-items-center">
                            <Link to="/businessDashboard">
                                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                            </Link>
                            <h5 className="add-offers-title">Add Offer</h5>
                        </div>
                    </div>
                    <form className="add-offers-form" onSubmit={handleSubmit}>
                        <span>Select Offer Title</span>
                        <div className="add-offers-item">
                            <div className="add-offers-item-text">Add Custom Title</div>
                            <button
                                type="button"
                                className="btn btn-warning text-light"
                                onClick={() => setShowModal(true)}
                            >
                                Next
                            </button>
                        </div>
                        {offers.map((offer, index) => (
                            <div key={index} className="add-offers-item">
                                <div className="add-offers-item-text">{offer}</div>
                                <button
                                    type="button"
                                    className="btn btn-warning text-light"
                                    onClick={() => handleSelectOffer(offer)}
                                >
                                    Select
                                </button>
                            </div>
                        ))}
                        {previousOffer && (
                            <div className="selected-offer">
                                <h5 className="selected-offer-title">Previous Offer:</h5>
                                <p className="selected-offer-text">{previousOffer}</p>
                            </div>
                        )}
                        {selectedOffer && (
                            <div className="selected-offer">
                                <h5 className="selected-offer-title">Selected Offer:</h5>
                                <p className="selected-offer-text">{selectedOffer}</p>
                                <button type="submit" className="btn btn-warning text-light">
                                    Apply Offer
                                </button>
                            </div>
                        )}
                    </form>
                </Card>
            </div>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? "show d-block" : ""}`} tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered add-offers-modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Custom Title</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowModal(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                className="form-control"
                                value={customTitle}
                                onChange={handleCustomTitleChange}
                                placeholder="Add Custom Title"
                            />
                        </div>
                        <div className="modal-footer d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-warning text-light"
                                onClick={handleCustomTitleSubmit}
                            >
                                Continue
                            </button>
                            <button
                                type="button"
                                className="btn btn-warning text-light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddOffers;
