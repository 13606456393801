import React, { useState } from "react";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

const AddWhatsApp = () => {

    const selectedBusiness = useSelector(store => store.selectedBusiness)
    const businessProfile = useSelector(store => store.businessProfile)
    console.log(businessProfile)
    const [whatsApp, setAddWhatsApp] = useState(businessProfile?.contact?.whatsApp)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        try {
            backendHost.post(`/businessProfile/addWhatsApp/${selectedBusiness._id}`, { whatsApp })
                .then(res => {
                    dispatch(showToast({ message: res?.data?.message, type: 'success' }))
                    dispatch(setBusinessProfile(res?.data?.businessProfile))
                    navigate("/businessDashboard")
                })
                .catch(err => {
                    console.log(err?.message);
                    dispatch(showToast({ type: 'error', message: err?.response?.data?.message }))
                })
        } catch (error) {
            console.log(error)
            dispatch(showToast({ type: 'error', message: error?.message }))
        }
    }
    return (
        <div className="container">
            <div className="ms-5 me-5">
                <Card className="">
                    <div className="name-heding">
                        <div className="d-flex ">
                            <Link to="/businessDashboard">
                                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                            </Link>
                            <h5 className=" business-heding">Add WhatsApp Number</h5>
                        </div>
                    </div>
                    <div className="conten">
                        <span>
                            Save Update your contact details to stay in touch with your
                            customers in real time.
                        </span>

                        <form className="pt-4" onSubmit={handleSubmit}>
                            <label>WhatsApp Number</label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    minLength={10}
                                    maxLength={13}
                                    placeholder="Add Whats App Number"
                                    name="name"
                                    value={whatsApp}
                                    onChange={(e) => setAddWhatsApp(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center pt-2 pb-2">
                                <button type="submit" class="btn btn-warning text-white" style={{ width: "200px" }}>
                                    SAVE
                                </button>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default AddWhatsApp