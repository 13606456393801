import React from 'react'
import { useSelector } from 'react-redux'

const AboutBusinessHero = () => {
    const selectedBusiness = useSelector(store => store?.selectedBusiness);
    return (
        <main className="main-content d-flex">
            <div className="image-section">
                <img
                    src={selectedBusiness?.businessLogo}
                    alt="Cleaning Service"
                    className="img-fluid"
                />
            </div>
            <div className="text-section bg-primary text-white d-flex flex-column justify-content-center">
                <h1 className="text-white">{selectedBusiness?.businessName} </h1>
                <p>We Provide best {selectedBusiness?.category} services</p>
            </div>
        </main>
    )
}
export default AboutBusinessHero