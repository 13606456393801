import React from "react";
import "./Sec4.css";
import sec4img1 from "../../../Assets/sec4-1.jpg";
import sec4img2 from "../../../Assets/sec4-2.jpg";
import sec4img3 from "../../../Assets/sec4-3.jpg";

function Sec4() {
  return (
    <div className="sec4">
      <div className="container">
        <div className="row p-5">
          <div class="col ">
            <p className="ser-text">BLOG</p>
            <h2 className="ser-head">Latest News</h2>
          </div>
          <div class="col">
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              usmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div class="aon-blog-style-1 media-bg-animate mba-bdr-20">
              <div class="post-bx">
                <div class="post-thum shine-box">
                  <img
                    title="title"
                    alt=""
                    src={sec4img1}
                    style={{ width: "420px" }}
                  />
                </div>
                <div class="post-info">
                  <div class="post-categories">
                    <a href="/">Logistics</a>
                  </div>
                  <div class="post-meta">
                    <ul>
                      <li class="post-date">
                        <span>June 18, 2022</span>
                      </li>
                      <li class="post-author">
                        By
                        <a href="/" title="Posts by admin" rel="author">
                          Nina Brown
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="post-text">
                    <h4 class="post-title">
                      <a href="blog-detail.html">
                        Helping Companies in Their Green Transition
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ">
            <div class="aon-blog-style-1 media-bg-animate mba-bdr-20">
              <div class="post-bx">
                <div class="post-thum shine-box">
                  <img
                    title="title"
                    alt=""
                    src={sec4img2}
                    style={{ width: "420px" }}
                  />
                </div>
                <div class="post-info">
                  <div class="post-categories">
                    <a href="/">BUSINESS</a>
                  </div>
                  <div class="post-meta">
                    <ul>
                      <li class="post-date">
                        <span>June 18, 2022</span>
                      </li>
                      <li class="post-author">
                        By
                        <a href="/" title="Posts by admin" rel="author">
                          Nina Brown
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="post-text">
                    <h4 class="post-title">
                      <a href="blog-detail.html">
                        There are two thing is very important in Consectetur
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ">
            <div class="aon-blog-style-1 media-bg-animate mba-bdr-20">
              <div class="post-bx">
                <div class="post-thum shine-box">
                  <img
                    title="title"
                    alt=""
                    src={sec4img3}
                    style={{ width: "420px" }}
                  />
                </div>
                <div class="post-info">
                  <div class="post-categories">
                    <a href="/">TRAVELING</a>
                  </div>
                  <div class="post-meta">
                    <ul>
                      <li class="post-date">
                        <span>June 18, 2022</span>
                      </li>
                      <li class="post-author">
                        By
                        <a href="/" title="Posts by admin" rel="author">
                          Nina Brown
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="post-text">
                    <h4 class="post-title">
                      <a href="blog-detail.html">
                        Officia deserunt mollit anim id est labrum. duis
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec4;
