import React, { useState } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import "./Login.css"
import { useDispatch } from "react-redux";
import { setUser } from "../../Store/Slices/userSlice";
import backendHost from "../../Api";
import { showToast } from "../../Store/Slices/toastSlice";


function Login() {

    const navigate = useNavigate();
    const location = useLocation();
    const { from } = location?.state || { from: { pathname: "/" } };
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const submit = (e) => {
        e.preventDefault();
        try {
            backendHost.post("/user/login", {
                email, password
            })
                .then(res => {
                    dispatch(showToast({ message: res?.data?.message, type: 'success' }))
                    dispatch(setUser(res.data.user))
                    navigate(from);
                })
                .catch(error => {
                    console.log(error, "INSIDE")
                    dispatch(showToast({ message: error?.response?.data?.message, type: "error" }))
                })
        } catch (error) {
            console.log(error?.message, "OUTSITE", error)
            dispatch(showToast({ message: error?.message, type: 'error' }))
            // Handle error
        }

    }


    return (
        <div className="containers">
            <input type="checkbox" id="check" />
            <div className="login form">
                <header className="login-1">Login</header>
                <form onSubmit={submit}>
                    <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter your email" />
                    <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Enter your password" />
                    <input type="submit" className="button" value="Login" o />
                </form>
                <div className="signup">
                    <span className="signup">Don't have an account?
                        <Link to="/signup">Signup Page</Link>
                    </span>
                </div>
            </div>
        </div>

    )
}

export default Login
