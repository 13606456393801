import React, { useEffect, useState } from "react";
import "./Sec3.css";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../../Api";
import { setUser } from "../../../Store/Slices/userSlice";
import { showToast } from "../../../Store/Slices/toastSlice";

function Sec3() {

  const dispatch = useDispatch()
  const user = useSelector(store => store.user)

  const [packages, setPackages] = useState([]);
  const handlePlan = (pkg) => {
    if (user?.userName) {
      try {
        backendHost
          .post(`/package/buyPackage/${pkg?._id}`, {
            user,
            pkg
          })
          .then((res) => {
            console.log("Data:", res?.data)
            if (res?.data?.success && res?.data?.package) {
              dispatch(setUser(res?.data?.user))
              dispatch(showToast({ message: res?.data?.message, type: "success" }));
            }
            else if (res?.data?.success) {
              dispatch(setUser(res?.data?.user))
              dispatch(showToast({ message: res?.data?.message, type: "success" }));
            }
            else if (res.data?.approvalUrl) {
              const approvalUrl = res.data.approvalUrl;
              window.location.href = approvalUrl;
            }
          })
          .catch((error) => {
            if (error?.response?.data.status === "Bought") {
              dispatch(showToast({ message: error?.response?.data?.message, type: 'error' }))
            }
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(showToast({ message: "Please Login for Subscription", type: 'error' }));
    }
  }
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        // axios.get("https://userserver.maxtechworld.com/package/getAllPackages")
        backendHost.get("/package/getAllPackages")
          .then(res => {
            setPackages(res?.data.packages)
          })
          .catch(err => console.log(err))
        // setPackages(response.data);

      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div className=""style={{backgroundColor:"#022279"}}>
      <div class="container" style={{height:"100px"}} >
        <div class="row p-5">
          <div class="col " >
            <p className="ser-text" style={{color:"#fff"}}>PRICING</p>
            <h2 className="ser-head"style={{color:"#FFC107"}}>Our Pricing Plans</h2>
          </div>
        </div>
      </div>
      <div className="package">
        {packages.map((pkg) => (
          <Card
            key={pkg._id}
            className="col1"
          >
            <CardContent className="card-content">
              <Typography variant="h5" component="h2" className="packege-title">
                {pkg.packageName}
              </Typography>
              <Typography className="packege-title-1" gutterBottom>
                <span className="packege-span">${pkg.packagePrice}</span> /month
              </Typography>
              <ul>
                {pkg.packagePros.map((packagePros, index) => (
                  <li className="packages-li" key={index}>
                    <DoneIcon className="package-icon" />
                    {packagePros}
                  </li>
                ))}
                {pkg.packageCons.map((packageCons, index) => (
                  <li className="packages-li" key={index}>
                    <CloseIcon className="package-icon-1" />
                    {packageCons}
                  </li>
                ))}
              </ul>
            </CardContent>
            <div className="d-flex justify-content-center package-btn">
              <button
                variant="contained"
                className="packages-btn"
                onClick={() => handlePlan(pkg)}
              >
                Choose Plan
              </button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Sec3;