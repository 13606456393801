import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from "@mui/icons-material/Search";
import CategoryIcon from "@mui/icons-material/Category";
import LanguageIcon from "@mui/icons-material/Language";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { Country, State, City } from "country-state-city";
import backendHost from '../../Api';
import { filterBusinesses } from '../../Store/Slices/allBusinessessSlice';

const SearchSidebar = () => {
    const businesses = useSelector(store => store?.allBusinesses?.filtered)
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        keyword: "",
        location: "",
        category: "",
        subcategory: "",
        country: "",
        state: "",
        city: "",
    });
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categorySubcategoryMap, setCategorySubcategoryMap] = useState({});

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormData({ ...formData, [name]: value });

        if (name === "country") {
            const countryStates = State?.getStatesOfCountry(value);
            setStates(countryStates);
            setCities([]);
        }
        if (name === "state") {
            const stateCities = City?.getCitiesOfState(formData.country, value);
            setCities(stateCities);
        }
        if (name === "category") {
            setFormData({ ...formData, category: value, subcategory: "" });
        }
    };

    useEffect(() => {
        backendHost.get("/business/getCategoriesgetSubCategories")
            .then((res) => {
                setCategories(res?.data?.categories);
                setCategorySubcategoryMap(res?.data?.categorySubcategoryMap);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const countryName = Country?.getCountryByCode(formData?.country)?.name || "";
        const stateName = State?.getStateByCodeAndCountry(formData?.state, formData?.country)?.name || "";

        // Create filtered data object
        const filters = {
            ...formData,
            country: countryName,
            state: stateName
        };
        dispatch(filterBusinesses(filters));
    };

    return (
        <div className="search-filter col-md-3 col-sm-12 sidebar">
            <form className="fiter-from" onSubmit={handleSubmit}>
                <div className="fiter-from-div filtersParentDiv1">
                    {/* <div className="d-flex justify-content-between m-3"> */}
                    <label>Keyword

                        <SearchIcon />
                    </label>
                    {/* </div> */}
                    <input
                        type="text"
                        name="keyword"
                        value={formData?.keyword}
                        onChange={handleChange}
                        placeholder="Keyword"
                        className='form-control'
                    />
                </div>
                {/* <div>
                    <div className="d-flex justify-content-between m-3">
                        <label>Location</label>
                        <LocationOnIcon />
                    </div>
                    <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        placeholder="Address"
                    />
                </div> */}
                <div className='searchSideBarDropDownParent' >
                    <div className="d-flex justify-content-between m-3 filtersParentDiv">
                        <label>Category</label>
                        <CategoryIcon />
                    </div>
                    <select
                        className="searchSideBarDropdown "
                        name="category"

                        style={{ width: "88.75%", marginLeft: "1.2vw", borderRadius: ".3vw" }}
                        value={formData?.category}
                        onChange={handleChange}
                    >
                        <option value="">Select Category</option>
                        {categories?.map((category, i) => (
                            <option key={i} value={category?.name}>
                                {category?.name}
                            </option>
                        ))}
                    </select>
                </div>
                {formData?.category && (
                    <div className='searchSideBarDropDownParent ' >
                        <div className="d-flex justify-content-between m-3 filtersParentDiv">
                            <label>Subcategory</label>
                            <CategoryIcon />
                        </div>
                        <select
                            className="searchSideBarDropdown"
                            name="subcategory"

                            style={{ width: "88.75%", marginLeft: "1.2vw", borderRadius: ".3vw" }}
                            value={formData?.subcategory}
                            onChange={handleChange}
                        >
                            <option value="">Select Subcategory</option>
                            {categorySubcategoryMap[formData?.category]?.map(
                                (subcategory, index) => (
                                    <option key={index} value={subcategory}>
                                        {subcategory}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                )}
                <div className='searchSideBarDropDownParent ' >
                    <div className="d-flex justify-content-between m-3 filtersParentDiv">
                        <label>Country</label>
                        <LanguageIcon />
                    </div>
                    <select
                        className="searchSideBarDropdown"
                        name="country"

                        style={{ width: "88.75%", marginLeft: "1.2vw", borderRadius: ".3vw" }}
                        value={formData?.country}
                        onChange={handleChange}
                    >
                        <option value="">Select Country</option>
                        {Country?.getAllCountries()?.map((country) => (
                            (country?.name === 'United States' || country?.name === 'Canada') && (
                                <option
                                    key={country?.isoCode}
                                    value={country?.isoCode}
                                >
                                    {country?.name}
                                </option>
                            )
                        ))}
                    </select>
                </div>
                <div className='searchSideBarDropDownParent ' >
                    <div className="d-flex justify-content-between m-3 filtersParentDiv">
                        <label>State</label>
                        <LocationCityIcon />
                    </div>
                    <select
                        className="searchSideBarDropdown"
                        name="state"
                        value={formData.state}
                        style={{ width: "88.75%", marginLeft: "1.2vw", borderRadius: ".3vw" }}
                        onChange={handleChange}
                        disabled={!states?.length}
                    >
                        <option value="">Select State</option>
                        {states?.map((state) => (
                            <option key={state?.isoCode} value={state?.isoCode}>
                                {state?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='searchSideBarDropDownParent ' >
                    <div className="d-flex justify-content-between m-3 filtersParentDiv">
                        <label>City</label>
                        <LocationCityIcon />
                    </div>
                    <select
                        className="searchSideBarDropdown"
                        name="city"

                        style={{ width: "88.75%", marginLeft: "1.2vw", borderRadius: ".3vw" }}
                        value={formData?.city}
                        onChange={handleChange}
                        disabled={!cities?.length}
                    >
                        <option value="">Select City</option>
                        {cities?.map((city) => (
                            <option key={city?.name} value={city?.name}>
                                {city?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button className="btn csButton searchCSButton" type="submit">
                    Search
                </button>
            </form>
        </div>
    )
}

export default SearchSidebar