import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import "./UpdateBT.css";
import backendHost from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

const getShortDay = (d) => {
    const daysMap = {
        Monday: "Mon",
        Tuesday: "Tue",
        Wednesday: "Wed",
        Thursday: "Thu",
        Friday: "Fri",
        Saturday: "Sat",
        Sunday: "Sun"
    };
    return daysMap[d] || d;
};

const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const UpdateBusinessTimings = () => {
    const businessProfile = useSelector(store => store.businessProfile);
    const selectedBusiness = useSelector(store => store.selectedBusiness);
    const dispatch = useDispatch();
    const [selectedDays, setSelectedDays] = useState([]);
    const [customTimings, setCustomTimings] = useState({
        Mon: { start: "", end: "" },
        Tue: { start: "", end: "" },
        Wed: { start: "", end: "" },
        Thu: { start: "", end: "" },
        Fri: { start: "", end: "" },
        Sat: { start: "", end: "" },
        Sun: { start: "", end: "" },
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleDaySelection = (day) => {
        setSelectedDays((prev) =>
            prev.includes(day)
                ? prev.filter((d) => d !== day)
                : [...prev, day]
        );
    };

    const handleCustomTimingChange = (day, field, value) => {
        setCustomTimings((prev) => ({
            ...prev,
            [day]: { ...prev[day], [field]: value },
        }));
    };

    const validateTimings = () => {
        const newErrors = {};
        selectedDays.forEach((day) => {
            const { start, end } = customTimings[day];
            if (start >= end) {
                newErrors[day] = "End time must be after start time.";
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateTimings()) {
            return;
        }
        const timings = selectedDays.map((day) => ({
            name: day,
            hours: `${customTimings[day].start} - ${customTimings[day].end}`
        }));

        try {
            backendHost.post(`/businessProfile/updateBusinessTimings/${selectedBusiness._id}`, { timings })
                .then(res => {
                    dispatch(showToast({ message: res?.data?.message, type: 'success' }));
                    dispatch(setBusinessProfile(res?.data?.businessProfile));
                    navigate("/businessDashboard");
                })
                .catch(err => {
                    console.log(err);
                    dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }));
                });
        } catch (error) {
            dispatch(showToast({ message: error?.message, type: 'error' }));
            console.error("Error updating business timings:", error);
        }
    };

    const sortedSelectedDays = [...selectedDays].sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
    const getSelectedTimings = (profile) => {
        if (profile?.timing?.days) {
            const newSelectedDays = [];
            const newCustomTimings = { ...customTimings };
            profile.timing.days.forEach(dayTiming => {
                const shortDay = getShortDay(dayTiming.name);
                console.log(shortDay)
                newSelectedDays.push(shortDay);
                const [start, end] = dayTiming.timeSlots[0].split(' - ');
                newCustomTimings[shortDay] = { start, end };
            });
            return { newSelectedDays, newCustomTimings };
        }
    }
    useEffect(() => {
        try {
            backendHost.get(`/businessProfile/getBusinessTimings/${businessProfile._id}`)
                .then(res => {
                    const profile = res?.data?.businessProfile;
                    dispatch(setBusinessProfile(profile));
                    const { newSelectedDays, newCustomTimings } = getSelectedTimings(profile);
                    setSelectedDays(newSelectedDays);
                    setCustomTimings(newCustomTimings);
                })
                .catch(err => {
                    console.log(err?.response?.data?.message);
                    dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }))
                });
        } catch (error) {
            console.log(error?.message);
        }
    }, [businessProfile._id]);

    return (
        <div className="uBTcontainer flex align-items-center justify-content-center">
            <div className="ms-5 me-5">
                <Card className="uBTcard">
                    <div className="uBTcard">
                        <div className="btname-heading">
                            <div className="d-flex uBTtopdiv">
                                <Link to="/businessDashboard">
                                    <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                                </Link>
                                <h5 className="business-heading flex align-items-center justify-content-center">Update Business Timings</h5>
                            </div>
                        </div>
                        <div className="contentuBT">
                            <span>
                                Let your customers know when to reach you. You can also configure
                                dual timing slots in a single day.
                            </span>

                            <form className="pt-4" onSubmit={handleSubmit}>
                                <label>Select Days Of the Week</label>
                                <div className="select-button">
                                    <div className="d-flex ">
                                        {dayOrder.map((day) => (
                                            <div
                                                key={day}
                                                className={`select-button-weeks ${selectedDays.includes(day) ? "selected" : ""
                                                    }`}
                                                onClick={() => handleDaySelection(day)}
                                            >
                                                {day}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="form-check m-3">
                                        <input
                                            className="form-check-input p-2"
                                            type="checkbox"
                                            value=""
                                            id="selectAllDays"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedDays(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
                                                } else {
                                                    setSelectedDays([]);
                                                }
                                            }}
                                            checked={selectedDays.length === 7}
                                        />
                                        <label className="form-check-label" htmlFor="selectAllDays">
                                            Select All Days
                                        </label>
                                    </div>
                                    <div className="mt-3">
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const newTimings = {};
                                                selectedDays.forEach((day) => {
                                                    if (value === "default") {
                                                        const { newSelectedDays, newCustomTimings } = getSelectedTimings(businessProfile);
                                                        setSelectedDays(newSelectedDays);
                                                        setCustomTimings(newCustomTimings);
                                                    } else if (value === "2") {
                                                        newTimings[day] = { start: "09:00", end: "18:00" };
                                                    } else if (value === "3") {
                                                        newTimings[day] = { start: "10:00", end: "17:00" };
                                                    } else if (value === "4") {
                                                        newTimings[day] = { start: "11:00", end: "22:00" };
                                                    } else {
                                                        newTimings[day] = { start: "", end: "" };
                                                    }
                                                });
                                                setCustomTimings((prev) => ({ ...prev, ...newTimings }));
                                            }}
                                        >
                                            <option value="default" selected>Custom Timings</option>
                                            <option value="2">9 Am to 6 Pm</option>
                                            <option value="3">10 Am to 5 Pm</option>
                                            <option value="4">11 Am to 10 Pm</option>
                                        </select>
                                    </div>
                                </div>

                                {sortedSelectedDays.map((day) => (
                                    <div key={day} className="row mt-3">
                                        <div className="col-md-3">
                                            <label>{day}</label>
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="time"
                                                className="form-control"
                                                value={customTimings[day].start}
                                                onChange={(e) => handleCustomTimingChange(day, "start", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="time"
                                                className="form-control"
                                                value={customTimings[day].end}
                                                onChange={(e) => handleCustomTimingChange(day, "end", e.target.value)}
                                            />
                                        </div>
                                        {errors[day] && (
                                            <div className="col-md-12 text-danger">
                                                {errors[day]}
                                            </div>
                                        )}
                                    </div>
                                ))}

                                <div className="d-flex justify-content-center align-items-center pt-5 pb-2">
                                    <button
                                        type="submit"
                                        className="btn btn-warning text-white"
                                        style={{ width: "200px" }}
                                    >
                                        SAVE
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default UpdateBusinessTimings;
