import React, { useEffect, useState } from 'react';
import "./CategoryDetails.css";
import { Container, Row, Col } from 'react-bootstrap';
import Navbar from '../../Components/home/navbar/Navbar';
import Footer from '../../Components/home/footer/Footer';
import CardComponent from "../../Components/CategoriesDetails/CardComponent";
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../Store/Slices/toastSlice';
import { useNavigate, useParams } from 'react-router-dom';
import backendHost from '../../Api';
import { setCategory } from '../../Store/Slices/categorySlice';
import { setAllBusinesses } from '../../Store/Slices/allBusinessessSlice';
import { setCategories } from '../../Store/Slices/categoriesSlice';

const CategoriesDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const category = useSelector(store => store?.category);
  const categories = useSelector(store => store?.categories);
  const businesses = useSelector(store => store?.allBusinesses?.filtered);
  const [sortBy, setSortBy] = useState(""); // 'title', 'rating'
  const [sortDirection, setSortDirection] = useState("ASC"); // 'ASC' or 'DESC'
  const { id } = useParams();

  useEffect(() => {
    if (id === "undefined") {
      navigate("/");
    } else {
      fetchCategoryDetails();
      fetchCategories();
    }
  }, [id, navigate, dispatch]);

  const fetchCategoryDetails = () => {
    backendHost.get(`/category/getCategoryDetailsAndBusinessLists/${id}`)
      .then(res => {
        dispatch(setCategory(res?.data?.data?.category));
        dispatch(setAllBusinesses(res?.data?.data?.businesses));
      })
      .catch(err => {
        dispatch(showToast({ type: 'error', message: err?.response?.data?.message, show: true }));
      });
  };

  const fetchCategories = () => {
    backendHost.get("/category/getCategories")
      .then(res => {
        dispatch(setCategories(res?.data?.categories));
      })
      .catch(err => {
        dispatch(showToast({ type: 'error', message: err?.response?.data?.message, show: true }));
      });
  };

  const getSortedBusinesses = () => {
    const businessesCopy = [...businesses];

    if (sortBy === 'title') {
      businessesCopy?.sort((a, b) => {
        if (sortDirection === 'ASC') {
          return a?.businessName?.localeCompare(b?.businessName);
        } else {
          return b?.businessName?.localeCompare(a?.businessName);
        }
      });
    } else if (sortBy === 'rating') {
      businessesCopy?.sort((a, b) => {
        if (sortDirection === 'ASC') {
          return a?.rating - b?.rating;
        } else {
          return b?.rating - a?.rating;
        }
      });
    }

    return businessesCopy;
  };

  const handleSortBy = (criteria) => {
    if (criteria === sortBy) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortBy(criteria);
      setSortDirection('ASC');
    }
  };

  return (
    <div>
      <Navbar />
      <div className='CategoriesDetails'>
        <div className='CategoriesDetails-head-1'>
          <div className='categories-1'>
            <div className="category-image-container">
              <div className="category-image-container2">
                <img src={category?.logo} alt='' className='categori-img' />
              </div>
            </div>
          </div>
          <div className='categories-2'>
            <p className='categories-2-1'>
              <span>Project categories / </span>
              {category?.name}
            </p>
            <h5 className="categories-h1">{category?.name} Services</h5>
            <p>
              Pro house cleaners work with you to assess your home cleaning needs. Whether you need weekly, bi-weekly, or monthly services, house cleaning professionals can schedule cleanings, so that your home has routine care. They can also deep clean the house in between cleaning, before special occasions, or for move-in and move-out cleaning.
            </p>
            <p className='pt-3'>
              On average, house cleaners on Zaarly cost $30 - $50 per hour per house cleaner. The price will depend on factors like size of the home, supplies needed, and special cleaning circumstances. A 2000 square foot home will typically cost $250. One-time deep cleanings will cost more and signing up for regular cleaning usually will be more cost-effective.
            </p>
          </div>
        </div>
        <div className='CategoriesDetails-head-2'>
          <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              {Array.from({ length: Math.ceil(categories.length / 3) }).map((_, idx) => (
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to={idx}
                  className={idx === 0 ? "active" : ""}
                  aria-current={idx === 0 ? "true" : ""}
                  aria-label={`Slide ${idx + 1}`}
                  key={idx}
                ></button>
              ))}
            </div>
            <div className="carousel-inner">
              {Array.from({ length: Math.ceil(categories.length / 3) }).map((_, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                  <div className="row">
                    {categories?.slice(index * 3, index * 3 + 3).map((cat, idx) => (
                      <div className="col-md-4 category-dets-card" key={idx} onClick={() => navigate(`/categoryDetails/${cat?._id}`)} >
                        <div className="cardcd" style={{ borderRadius: "10px" }} >
                          <div className="cardcd-image-container">
                            <div className="cardcd-image-container2">
                              <img src={cat.logo} className="card-img-top" alt={cat.name} />
                            </div>
                          </div>
                          <div className="cardcd-body">
                            <h5 className="cardcd-title">{cat.name}</h5>
                            {/* <p className="card-text">{cat.description}</p> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className='categoriesBooking'>
          <div className=''>
            <div className="search-cart-main d-flex justify-content-between align-items-center">
              <div className="search-cart-heading">
                <h5>{businesses?.length} results</h5>
              </div>
              <div className="search-cart-mini-filter d-flex p-2">
                <div className="dropdown p-2">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    SORT BY
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className={`dropdown-item ${sortBy === 'title' ? 'active' : ''}`} href="#" onClick={() => handleSortBy('title')}>
                        Title
                      </a>
                    </li>
                    <li>
                      <a className={`dropdown-item ${sortBy === 'rating' ? 'active' : ''}`} href="#" onClick={() => handleSortBy('rating')}>
                        Rating
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="dropdown p-2">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {sortDirection === 'ASC' ? 'ASC' : 'DESC'}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => setSortDirection('ASC')}>
                        ASC
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => setSortDirection('DESC')}>
                        DESC
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Container className="mt-4">
            <Row>
              {getSortedBusinesses()?.map((business, index) => (
                <Col key={index} xs={12} md={6}>
                  <CardComponent business={business?.business} profile={business?.profile} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CategoriesDetails;
