import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./BusinessCard.module.css";
import { useSelector } from 'react-redux';

const BusinessCard = () => {
    const selectedBusiness = useSelector(store => store?.selectedBusiness);
    const businessProfile = useSelector(store => store?.businessProfile);

    const splitBusinessName = (name) => {
        if (!name) return { firstHalf: "", secondHalf: "" };
        const mid = Math.ceil(name?.length / 2);
        return {
            firstHalf: name?.slice(0, mid),
            secondHalf: name?.slice(mid)
        };
    };

    const { firstHalf, secondHalf } = splitBusinessName(selectedBusiness?.businessName);

    const getPlatformIcon = (platform) => {
        switch (platform) {
            case 'facebook':
                return 'ion-social-facebook';
            case 'twitter':
                return 'ion-social-twitter';
            case 'linkedin':
                return 'ion-social-linkedin';
            case 'youtube':
                return 'ion-social-youtube';
            case 'instagram':
                return 'ion-social-instagram';
            default:
                return '';
        }
    };

    const socialMediaLink = businessProfile?.socialMediaLinks?.[0];

    const shareUrls = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${encodeURIComponent(`Check out this business: ${secondHalf}`)}`,
        whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(`Check out this business: ${secondHalf} - ${window.location.href}`)}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${encodeURIComponent('Business Card')}&summary=${encodeURIComponent(`Check out this business: ${secondHalf}`)}`,
        email: `mailto:?subject=${encodeURIComponent('Business Card')}&body=${encodeURIComponent(`Check out this business: ${secondHalf} - ${window.location.href}`)}`
        // Note: Instagram does not provide a direct sharing URL like others
    };

    return (
        <div className={styles?.container}>
            <div className={styles?.bgtint}></div>
            <Link to="/businessDashboard" className={styles?.dashboardLink}>
                <span className="ion-arrow-left-c"></span> Business Dashboard
            </Link>
            <section className={styles?.businesscard}>
                <div className={styles?.flip}>
                    <div className={styles?.front}>
                        <div className={styles?.top}>
                            {selectedBusiness?.businessName?.length > 0 && (
                                <div className={styles?.logo}>
                                    <span className={styles?.fat}>{firstHalf}</span>
                                    <span className={styles?.skinny}>{secondHalf}</span>
                                </div>
                            )}
                        </div>
                        <div className={styles?.nametroduction}>
                            <div className={styles?.name}>{selectedBusiness?.ownerFirstName + " " + selectedBusiness?.ownerLastName}</div>
                            <div className={styles?.introduction}>A {selectedBusiness?.category} service provider</div>
                        </div>
                        <div className={styles?.contact}>
                            {businessProfile?.websites?.length > 0 &&
                                <div className={styles?.website} style={{ whiteSpace: 'nowrap' }}>
                                    <span className="ion-earth"></span>
                                    <a href={businessProfile?.websites[0]} target='_blank'>{businessProfile?.websites[0]}</a>
                                </div>
                            }
                            {socialMediaLink &&
                                <div className={styles?.socialMedia} style={{ whiteSpace: 'nowrap' }}>
                                    <span className={getPlatformIcon(socialMediaLink.platform)}></span>
                                    <a href={socialMediaLink?.link} target='_blank'>{socialMediaLink?.link}</a>
                                </div>
                            }
                            {businessProfile?.contact?.mobile1 &&
                                <div className={styles?.phone} style={{ whiteSpace: 'nowrap' }}>
                                    <span className="ion-ios7-telephone"></span>
                                    <a href={`tel:${businessProfile?.contact?.mobile1}`}>{businessProfile?.contact?.mobile1}</a>
                                </div>
                            }
                            {selectedBusiness?.email &&
                                <div className={styles?.email} style={{ whiteSpace: 'nowrap' }}>
                                    <span className="ion-email"></span>
                                    <a href={`mailto:${selectedBusiness?.email}`}>{selectedBusiness?.email}</a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles?.back}></div>
                </div>
            </section>
            <div className={styles?.shareButtons} >
                <h2 className={styles?.shareLinksHeader} >Share business card on different plaforms...</h2>
                <Link to={shareUrls?.facebook} target="_blank" rel="noopener noreferrer">
                    <button className={styles?.shareButton}><span className="ion-social-facebook"></span> Share on Facebook</button>
                </Link>
                <Link to={shareUrls?.whatsapp} target="_blank" rel="noopener noreferrer">
                    <button className={styles?.shareButton}><span className="ion-social-whatsapp"></span> Share on WhatsApp</button>
                </Link>
                <Link to={shareUrls?.linkedin} target="_blank" rel="noopener noreferrer">
                    <button className={styles?.shareButton}><span className="ion-social-linkedin"></span> Share on LinkedIn</button>
                </Link>
                <Link to={shareUrls?.email} target="_blank" rel="noopener noreferrer">
                    <button className={styles?.shareButton}><span className="ion-email"></span> Share via Email</button>
                </Link>
                <Link to="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className={styles?.shareButton} style={{ width: "fit-content" }}>
                    <span className="ion-social-instagram"></span> Share on Instagram
                </Link>
            </div>
            <section className={styles?.tooltip}>
                <p>Hover over the card to see the back.</p>
            </section>
        </div>
    );

}

export default BusinessCard;
