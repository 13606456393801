import React from "react";
import "../Categories/Categories.css";
import "./Sec2.css";
import logo1 from "../../../Assets/1.jpg";
import logo2 from "../../../Assets/2 (1).jpg";
import logo3 from "../../../Assets/3 (1).jpg";
import logo4 from "../../../Assets/4.jpg";
import logo5 from "../../../Assets/5.jpg";
import logo6 from "../../../Assets/6.jpg";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function Sec2() {
  return (
    <div className="services">
      <div class="container">
        <div class="row p-5">
          <div class="col ">
            <p className="ser-text">JOBS</p>
            <h2 className="ser-head">Recently Posted Jobs</h2>
          </div>
          <div class="col">
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              usmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
            <div className="media-bg-animate mba-bdr-15">
              <div className="aon-categories-area2-iconbox aon-icon-effect">
                <div className="sec2-logo ">
                  <img src={logo1} className="sec2-img" />
                </div>
                <div className="sec2-text">
                  <h3 className="sec2-heding">UI & UX Designer</h3>
                  <p className="sec2-para">Digital Asset</p>
                  <div className="ser2-ylr">
                    <div className="ylr1">
                      <CalendarTodayIcon />
                      <p>3 years ago</p>
                    </div>
                    <div className="ylr1">
                      <LocationOnIcon />
                      <p> Brooklyn</p>
                    </div>
                    <div className="ylr1">
                      <LocalAtmIcon />
                      <p className="ylr2"> $1,200 - $1,500</p>
                    </div>
                  </div>
                  <div className="ser2-b">
                    <ul className="ser-b2">
                      <li>
                        <FiberManualRecordIcon />
                        Full Time
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 mb-4">
            <div className="media-bg-animate mba-bdr-15">
              <div className="aon-categories-area2-iconbox aon-icon-effect">
                <div className="sec2-logo ">
                  <img src={logo2} className="sec2-img" />
                </div>
                <div className="sec2-text">
                  <h3 className="sec2-heding">Web & App developer</h3>
                  <p className="sec2-para">Digital Asset</p>
                  <div className="ser2-ylr">
                    <div className="ylr1">
                      <CalendarTodayIcon />
                      <p>3 years ago</p>
                    </div>
                    <div className="ylr1">
                      <LocationOnIcon />
                      <p> Brooklyn</p>
                    </div>
                    <div className="ylr1">
                      <LocalAtmIcon />
                      <p className="ylr2"> $1,200 - $1,500</p>
                    </div>
                  </div>
                  <div className="ser2-b">
                    <ul className="ser-b2">
                      <li>
                        <FiberManualRecordIcon />
                        Hourly
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
            <div className="media-bg-animate mba-bdr-15">
              <div className="aon-categories-area2-iconbox aon-icon-effect">
                <div className="sec2-logo ">
                  <img src={logo3} className="sec2-img" />
                </div>
                <div className="sec2-text">
                  <h3 className="sec2-heding">Hotel Interior Designer</h3>
                  <p className="sec2-para">Digital Asset</p>
                  <div className="ser2-ylr">
                    <div className="ylr1">
                      <CalendarTodayIcon />
                      <p>3 years ago</p>
                    </div>
                    <div className="ylr1">
                      <LocationOnIcon />
                      <p> Brooklyn</p>
                    </div>
                    <div className="ylr1">
                      <LocalAtmIcon />
                      <p className="ylr2"> $1,200 - $1,500</p>
                    </div>
                  </div>
                  <div className="ser2-b">
                    <ul className="ser-b2">
                      <li>
                        <FiberManualRecordIcon />
                        Hourly
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 mb-4">
            <div className="media-bg-animate mba-bdr-15">
              <div className="aon-categories-area2-iconbox aon-icon-effect">
                <div className="sec2-logo ">
                  <img src={logo4} className="sec2-img" />
                </div>
                <div className="sec2-text">
                  <h3 className="sec2-heding">Digital Marketing Agency</h3>
                  <p className="sec2-para">Digital Asset</p>
                  <div className="ser2-ylr">
                    <div className="ylr1">
                      <CalendarTodayIcon />
                      <p>3 years ago</p>
                    </div>
                    <div className="ylr1">
                      <LocationOnIcon />
                      <p> Brooklyn</p>
                    </div>
                    <div className="ylr1">
                      <LocalAtmIcon />
                      <p className="ylr2"> $1,200 - $1,500</p>
                    </div>
                  </div>
                  <div className="ser2-b">
                    <ul className="ser-b2">
                      <li>
                        <FiberManualRecordIcon />
                        Hourly
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
            <div className="media-bg-animate mba-bdr-15">
              <div className="aon-categories-area2-iconbox aon-icon-effect">
                <div className="sec2-logo ">
                  <img src={logo5} className="sec2-img" />
                </div>
                <div className="sec2-text">
                  <h3 className="sec2-heding">Fish & Game Warden</h3>
                  <p className="sec2-para">Digital Asset</p>
                  <div className="ser2-ylr">
                    <div className="ylr1">
                      <CalendarTodayIcon />
                      <p>3 years ago</p>
                    </div>
                    <div className="ylr1">
                      <LocationOnIcon />
                      <p> Brooklyn</p>
                    </div>
                    <div className="ylr1">
                      <LocalAtmIcon />
                      <p className="ylr2"> $1,200 - $1,500</p>
                    </div>
                  </div>
                  <div className="ser2-b">
                    <ul className="ser-b2">
                      <li>
                        <FiberManualRecordIcon />
                        Hourly
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 mb-4">
            <div className="media-bg-animate mba-bdr-15">
              <div className="aon-categories-area2-iconbox aon-icon-effect">
                <div className="sec2-logo ">
                  <img src={logo6} className="sec2-img" />
                </div>
                <div className="sec2-text">
                  <h3 className="sec2-heding">Certified Nursing Assistantr</h3>
                  <p className="sec2-para">Digital Asset</p>
                  <div className="ser2-ylr">
                    <div className="ylr1">
                      <CalendarTodayIcon />
                      <p>3 years ago</p>
                    </div>
                    <div className="ylr1">
                      <LocationOnIcon />
                      <p> Brooklyn</p>
                    </div>
                    <div className="ylr1">
                      <LocalAtmIcon />
                      <p className="ylr2"> $1,200 - $1,500</p>
                    </div>
                  </div>
                  <div className="ser2-b">
                    <ul className="ser-b2">
                      <li>
                        <FiberManualRecordIcon />
                        Hourly
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec2;
