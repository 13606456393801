// src/CardComponent.js
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Store/Slices/toastSlice';

const CardComponent = ({ business, profile }) => {
  const user = useSelector(store => store?.user)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBooking = () => {
    if (!user?._id) {
      navigate("/login")
      dispatch(showToast({ message: "Please login to continue", type: "warning", show: true }))
    } else {
      navigate("/addBooking/" + profile?._id)
    }
  }
  return (
    <>
      <Card className="mb-3" style={{ maxWidth: '100%', backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: "10px" }}>
        <Row className="g-0" style={{ padding: "10px", borderRadius: "12.5px", overflow: "hidden" }} >
          <Col xs={12} md={4} style={{ width: "50%", display: 'flex', alignItems: 'center', justifyContent: "center" }} >
            <div style={{ padding: '30px', borderRadius: "50%" }} >
              <Card.Img src={business?.businessLogo} style={{ width: "100%", height: "100%" }} className="img-fluid rounded-start" alt={business.businessName} />
            </div>
          </Col>
          <Col xs={12} md={8} style={{ width: "50%" }} >
            <Card.Body style={{ display: "flex", flexDirection: "column", alignItems: 'center', textAlign: 'start' }} >
              <Card.Title
                style={{ textAlign: 'start', width: "100%" }} >{business?.businessName}</Card.Title>
              <Card.Text
                style={{ textAlign: 'start', padding: "0", margin: '0', width: "100%" }}  ><LocationOnIcon style={{ marginLeft: "0", paddingLeft: "0px", display: 'inline-block', color: 'red' }} />{`${business?.address}, ${business?.city}, ${business?.state}, ${business?.country}, ${business?.pin}`}</Card.Text>
              <Card.Text
                style={{ textAlign: 'start', margin: '3px 0', padding: '0', fontWeight: "normal", width: "100%" }} >{business.description}</Card.Text>
              <button onClick={handleBooking} className="btn btn-primary mt-2 ">Enquire Now</button>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CardComponent;
