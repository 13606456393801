import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const categorySlice = createSlice({
    initialState,
    name: "category",
    reducers: {
        setCategory: (_, { payload }) => payload,
        resetCategory: () => initialState,
    },
});

export const { setCategory, resetCategory } = categorySlice.actions;

export default categorySlice?.reducer;
