import React, { useEffect } from "react";
import Navbar from "../../Components/home/navbar/Navbar";
import "./AboutBusiness.css";
import ReviewsAndComments from "../../Components/AboutBusiness/Reviews/ReviewsandComments"
import AddReview from "../../Components/AboutBusiness/AddReview/AddReview"
import BusinessContactInformation from "../../Components/AboutBusiness/BusinessContactInformation/BusinessContactInformation";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { showToast } from "../../Store/Slices/toastSlice";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import AboutServices from "../../Components/AboutBusiness/AboutBusiness-Services/AboutServices";
import AboutBusinessHero from "../../Components/AboutBusiness/AboutBusiness-TopSection/AboutBusinessHero";
import AboutProvider from "../../Components/AboutBusiness/AboutBusiness-TopSection/AboutProvider";
import AboutBusinessSocialMedia from "../../Components/AboutBusiness/AboutBusiness-TopSection/AboutBusinessSocialMedia";


const AboutBusiness = () => {
    const selectedBusiness = useSelector(store => store?.selectedBusiness)
    const businessProfile = useSelector(store => store?.businessProfile)
    const dispatch = useDispatch()
    console.log(businessProfile, selectedBusiness)
    useEffect(() => {
        try {
            backendHost.get(`/businessProfile/getBusinessProfile/${selectedBusiness._id}`)
                .then(res => dispatch(setBusinessProfile(res?.data?.businessProfile)))
                .catch(err => {
                    console.log(err)
                    dispatch(showToast({ type: 'error', message: err?.response?.data?.message }))
                })
        } catch (error) {
            dispatch(showToast({ type: 'error', message: error?.message }))
        }
    }, [])

    return (
        <div>
            <Navbar />
            <AboutBusinessHero />
            {/* <div className="nav-business-about bg-light text-Secondary">
                <ul className="nav justify-content-evenly fs-5">
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            About
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Services
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Request a Quote
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Contact Info
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Video
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Articles
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Review
                        </a>
                    </li>
                </ul>
            </div> */}
            <AboutProvider />
            <AboutBusinessSocialMedia />
            <AboutServices />
            <ReviewsAndComments />
            <AddReview />
            <BusinessContactInformation />
        </div>
    );
}

export default AboutBusiness;
