import React, { useState } from "react";
import "./AddCatalogue.css";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

function AddWebsite() {
    const businessProfile = useSelector(store => store?.businessProfile)
    const selectedBusiness = useSelector(store => store?.selectedBusiness)
    const [websites, setWebsites] = useState(businessProfile?.websites?.length > 0 ? businessProfile?.websites : [""]);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({});

    const handleAddWebsite = () => {
        if (websites.length < 3) {
            setWebsites([...websites, ""]);
        }
    };

    const handleWebsiteChange = (index, value) => {
        const newWebsites = websites.map((website, i) =>
            i === index ? value : website
        );
        setWebsites(newWebsites);
    };

    const isValidURL = (string) => {
        const res = string.match(
            /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm
        );
        return res !== null;
    };

    const handleSave = () => {
        try {
            const newErrors = {};
            websites.forEach((website, index) => {
                if (!isValidURL(website)) {
                    newErrors[index] = "Invalid URL";
                }
            });

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
            } else {
                setErrors({});
                console.log("All URLs are valid, proceed with save");
                console.log(websites)
                backendHost.post(`/businessProfile/addWebsites/${selectedBusiness?._id}`, { websites })
                    .then(res => {
                        console.log(res?.data)
                        dispatch(showToast({ type: 'success', message: res?.data?.message }))
                        dispatch(setBusinessProfile(res?.data?.businessProfile))
                        navigate("/businessDashboard")

                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(showToast({ type: 'error', message: err?.response?.data?.message }))
                    })
            }
        } catch (error) {
            console.log(error)
            dispatch(showToast({ type: 'error', message: error?.message }))
        }
    };
    return (
        <div className="container">
            <div className="ms-5 me-5">
                <Card className="">
                    <div className="name-heding">
                        <div className="d-flex ">
                            <Link to="/businessDashboard">
                                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                            </Link>
                            <h5 className=" business-heding">
                                Business Website
                            </h5>
                        </div>
                    </div>
                    <div className="conten">
                        <span>
                            Save and update your contact details to stay in touch
                            with your customers in real time.
                        </span>
                        <div>
                            {websites.map((website, index) => (
                                <div key={index}>
                                    <input
                                        type="url"
                                        placeholder="Enter Business Website URL"
                                        value={website}
                                        onChange={(e) =>
                                            handleWebsiteChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        className="form-control p-2 mt-3 rounded-3"
                                    />
                                    {errors[index] && (
                                        <div className="text-danger">
                                            {errors[index]}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div>
                            <button
                                onClick={handleAddWebsite}
                                className="btn btn-warning text-light m-2"
                                disabled={websites.length >= 3}
                            >
                                Add another website
                            </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center ">
                            <button
                                className="btn btn-warning text-light m-2 w-25"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default AddWebsite;
