import React from 'react';
import { Oval } from 'react-loader-spinner';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

const LoadingSpinner = () => {
    const loading = useSelector(store => store?.loading);

    if (!loading) return null;

    return (
        <div style={spinnerContainerStyle}>
            <div style={overlayStyle}></div>
            <Oval
                height="100%"
                width="100%"
                color="#FFB600"
                wrapperStyle={ovalStyle}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#E0A800"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ repeat: Infinity, duration: 1 }}
                style={loadingTextStyle}
            >
                Loading...
            </motion.div>
        </div>
    );
};

const spinnerContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100%',
    zIndex: 9999,
    overflow: "hidden",
};

const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.375)',
    zIndex: 1,
};

const ovalStyle = {
    maxWidth: '100px',
    maxHeight: '100px',
    width: '10vw',
    height: '10vw',
    zIndex: 2,
    color: "#FFB600"
};

const loadingTextStyle = {
    zIndex: 2,
    marginTop: '20px',
    fontSize: '1.5rem',
    color: '#FFB600',
};

export default LoadingSpinner;
