import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show: false,
    message: "",
    type: "info", // can be 'success', 'error', 'warning', 'info'
};

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        showToast: (state, action) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        hideToast: (state) => {
            state.show = false;
            state.message = "";
            state.type = "info";
        },
    },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
