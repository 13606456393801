import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const businessProfileSlice = createSlice({
    name: "businessProfile",
    initialState,
    reducers: {
        setBusinessProfile: (state, action) => {
            return action.payload;
        },
        resetBusinessProfile: () => {
            return initialState;
        },
    },
});

export const { setBusinessProfile, resetBusinessProfile } =
    businessProfileSlice.actions;
export default businessProfileSlice.reducer;
