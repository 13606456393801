import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const loadingSlice = createSlice({
    initialState,
    name: "loading",
    reducers: {
        startLoading: () => true,
        endLoading: () => initialState,
    },
});

export const { startLoading, endLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
