import React from "react";
import "./Sec6.css";

function Sec6() {
  return (
    <>
      <section className="aon-statics-area2">
        <div className="container">
          <div className="aon-statics-area2-section">
            <div className="aon-statics-area2-bg">
              <div className="section-head aon-title-center white">
                <span className="aon-sub-title">Statics</span>
                <h2 className="sf-title">
                  Trusted by thousands of people all over the world
                </h2>
              </div>

              <div className="section-content">
                <div className="aon-statics-blocks2">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-6">
                      <div className="aon-static-section2 aon-t-white2">
                        <div className="aon-company-static-num2 counter">
                          36
                        </div>
                        <div className="aon-company-static-name2">
                          Providers
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-6">
                      <div className="aon-static-section2 aon-t-skyblue2">
                        <div className="aon-company-static-num2 counter">
                          59
                        </div>
                        <div className="aon-company-static-name2">
                          Categories
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-6">
                      <div className="aon-static-section2 aon-t-yellow2">
                        <div className="aon-company-static-num2 counter">
                          108
                        </div>
                        <div className="aon-company-static-name2">Jobs</div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-6">
                      <div className="aon-static-section2 aon-t-green2">
                        <div className="aon-company-static-num2 counter">
                          89
                        </div>
                        <div className="aon-company-static-name2">Customer</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aon-newsletter-area2">
        <div className="container">
          <div className="aon-newsletter-area2-section">
            <h3 className="aon-title">
              We empower clients to grow their businesses based on the effective
              use of technology
            </h3>
            <p>
              Get connected with us to build a bright future..
            </p>
            <form className="aon-nl-width">
              <div className="form-group sf-news-l-form" >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Email"
                />
                <button type="submit" className="sf-sb-btn " style={{marginTop: "10px",paddingTop:"10px",paddingBottom:"10px",color:"#FFF"}} >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sec6;
