import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import backendHost from '../../Api';
import { setCategories } from '../../Store/Slices/categoriesSlice';
import { showToast } from '../../Store/Slices/toastSlice';
import Footer from '../../Components/home/footer/Footer';
import Navbar from '../../Components/home/navbar/Navbar';

const ViewAllCategories = () => {
    const categories = useSelector(store => store?.categories);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        try {
            backendHost.get("/category/getCategories&Subcategories&BusinessLists")
                .then(res => {
                    console.log(res?.data)
                    dispatch(setCategories(res?.data?.categories));
                })
                .catch(err => {
                    console.log(err)
                    dispatch(showToast({ type: "error", message: err?.response?.data?.message }))
                })
        } catch (error) {
            console.log(error)
            dispatch(showToast({ type: 'error', message: error?.message, show: true }))
        }
    }, [dispatch]);
    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    {categories?.map((category, index) => (
                        <div className="col-lg-4 col-md-6 mb-4" key={index} onClick={() => { navigate(`/categoryDetails/${category?.category?._id}`) }} >
                            <div className="media-bg-animate mba-bdr-15">
                                <div className="aon-categories-area2-iconbox aon-icon-effect">
                                    <div className="aon-cate-area2-icon">
                                        <span>
                                            <i className="aon-icon">
                                                <img src={category?.category?.logo} alt="" />
                                            </i>
                                        </span>
                                    </div>
                                    <div className="aon-cate-area2-content">
                                        <h4 className="aon-title">
                                            <a href="categories-detail.html">{category.category?.name}</a>
                                        </h4>
                                        <p>{category.businessCount} Listing{category.businessCount !== 1 && "s"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ViewAllCategories