import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'; // Assuming you're using Redux for state management
import axios from 'axios';
import backendHost from '../../Api'; // Adjust path as per your API setup
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../Components/home/footer/Footer';
import Navbar from '../../Components/home/navbar/Navbar';
import { showToast } from '../../Store/Slices/toastSlice';

const AddBookingPage = () => {
    const businessProfileId = useParams().id;
    const user = useSelector((state) => state.user);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const handleTimeChange = (e) => {
        setTime(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newBooking = {
            user: user._id,
            date,
            time,
        };
        try {
            backendHost.post(`businessProfile/addBooking/${businessProfileId}`, newBooking)
                .then((res) => {
                    console.log('Booking created successfully:', res.data);
                    dispatch(showToast({ message: res?.data?.message, type: 'success', show: true }))
                    navigate("/")
                })
                .catch((err) => {
                    console.error('Error creating booking:', err);
                    dispatch(showToast({ message: err?.response?.data?.message, type: 'error', show: true }))
                });
        } catch (error) {
            dispatch(showToast({ type: "error", message: error?.message, show: true }))
        }
    };

    return (
        <>
            <Navbar />
            <Container className="mt-4">
                <Row>
                    <Col>
                        <h2>Add Booking</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="date">
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={date}
                                    onChange={handleDateChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="time">
                                <Form.Label>Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={time}
                                    onChange={handleTimeChange}
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Confirm Booking
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default AddBookingPage;
