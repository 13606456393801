import React, { useState } from "react";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { showToast } from "../../Store/Slices/toastSlice";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";

function AddContact() {
    const selectedBusiness = useSelector(store => store.selectedBusiness)
    const businessProfile = useSelector(store => store.businessProfile)
    console.log(businessProfile)
    const [mobile1, setMobile1] = useState(businessProfile?.contact?.mobile1)
    const [mobile2, setMobile2] = useState(businessProfile?.contact?.mobile2)
    const [email, setEmail] = useState(businessProfile?.contact?.email)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        try {
            backendHost.post(`/businessProfile/addContact/${selectedBusiness._id}`, { mobile1, mobile2, email })
                .then(res => {
                    dispatch(setBusinessProfile(res?.data?.businessProfile))
                    navigate("/businessDashboard")
                    dispatch(showToast({ type: 'success', message: res?.data?.message }))
                })
                .catch(err => {
                    console.log(err?.message);
                    dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }))
                })
        } catch (error) {
            console.log(error)
            dispatch(showToast({ message: error?.message, type: 'error' }))

        }
    }
    return (
        <div className="container">
            <div className="ms-5 me-5">
                <Card className="">
                    <div className="name-heding">
                        <div className="d-flex ">
                            <Link to="/businessDashboard">
                                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                            </Link>
                            <h5 className=" business-heding">Add Contact Details</h5>
                        </div>
                    </div>
                    <div className="conten">
                        <span>
                            Save Update your contact details to stay in touch with your
                            customers in real time.
                        </span>

                        <form className="pt-4" onSubmit={handleSubmit}>
                            <label>Mobile Number</label>
                            <div class="input-group">
                                <input
                                    class=" form-control"
                                    type="text"
                                    placeholder="Mobile Number 1"
                                    minLength={10}
                                    maxLength={10}
                                    name="name"
                                    value={mobile1}
                                    onChange={(e) => setMobile1(e.target.value)}
                                />
                            </div>
                            <label> Add second mobile Number</label>
                            <div class="input-group">
                                <input
                                    class=" form-control"
                                    type="text"
                                    placeholder="Add Mobile Number 2"
                                    minLength={10}
                                    maxLength={13}
                                    name="name"
                                    value={mobile2}
                                    onChange={(e) => setMobile2(e.target.value)}
                                />
                            </div>
                            <label> Business Email :    </label>
                            <div class="input-group">
                                <input
                                    class=" form-control"
                                    type="email"
                                    placeholder="Add Email"
                                    name="name"
                                    maxLength={45}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center pt-2 pb-2">
                                <button type="submit" class="btn btn-warning text-white" style={{ width: "200px" }}>
                                    SAVE
                                </button>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default AddContact;
