import React from "react";
import "./Footer.css";
import logo from "../../../Assets/logo.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <footer className="site-footer footer-light">
        <div className="footer-top-newsletter">
          <div className="container">
            <div className="sf-news-letter">
              <span  style={{marginTop:"14px"}}>Subscribe Our Newsletter</span>
              <form>
                <div className="form-group sf-news-l-form">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Email"
                  />
                  <button type="submit" className="sf-sb-btn" style={{ marginTop: "10px",paddingTop:"10px",paddingBottom:"10px",color:"#FFF" }}  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6  m-b30">
                <div className="sf-site-link sf-widget-link">
                  <h4 className="sf-f-title">Site Links</h4>
                  <ul>
                    <li>
                      <a href="blog-grid.html">Blog</a>
                    </li>
                    <li>
                      <a href="contact-us.html">Contact Us</a>
                    </li>
                    <li>
                      <a href="job-grid.html">Jobs</a>
                    </li>
                    <li>
                      <a href="all-categories.html">Categories</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6  m-b30">
                <div className="sf-site-link sf-widget-cities">
                  <h4 className="sf-f-title">Popular Cities</h4>
                  <ul>
                    <li>
                      <a href="all-categories.html">Ballston Lake</a>
                    </li>
                    <li>
                      <a href="all-categories.html">Batumi</a>
                    </li>
                    <li>
                      <a href="all-categories.html">Brooklyn</a>
                    </li>
                    <li>
                      <a href="all-categories.html">Cambridge</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6  m-b30">
                <div className="sf-site-link sf-widget-categories">
                  <h4 className="sf-f-title">Categories</h4>
                  <ul>
                    <li>
                      <a href="categories-detail.html">Car Service</a>
                    </li>
                    <li>
                      <a href="categories-detail.html">House Cleaning</a>
                    </li>
                    <li>
                      <a href="categories-detail.html">Transport</a>
                    </li>
                    <li>
                      <a href="categories-detail.html">Yoga classNamees</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6  m-b30">
                <div className="sf-site-link sf-widget-contact">
                  <h4 className="sf-f-title">Contact Info</h4>
                  <ul>
                    <li>Maxtech World</li>
                    <li>Shop no. 17, 1st Floor, Dhananjay Tower, near Setelite, 100 Feet Rd, Anand Nagar, Satelite, Ahmedabad, Gujarat 380058</li>
                    <li>+91-9082145541</li>
                    <li>info@maxtechworld.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="sf-footer-bottom-section">
              <div className="sf-f-logo">
                <a href="javascript:void(0);">
                  <img src={logo} alt="" />
                </a>
              </div>
              <div className="sf-f-copyright">
                <span>Copyright 2022 | Aone Theme. All Rights Reserved</span>
              </div>

              <div className="sf-f-social">
                <ul className="socila-box">
                  <li>
                    <a href="javascript:void(0);">
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <FacebookIcon />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <InstagramIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button className="scroltop" onClick={scrollToTop} style={{ display: "inline-block" }}>
        <span className="fa fa-angle-up relative" id="btn-vibrate"></span>
      </button>
    </div>
  );
}

export default Footer;
