import { configureStore } from "@reduxjs/toolkit";
import user from "./Slices/userSlice";
import toast from "./Slices/toastSlice";
import selectedBusiness from "./Slices/selectedBusinessSlice";
import businessProfile from "./Slices/businessProfileSlice";
import businessProfiles from "./Slices/businessProfilesSlice";
import allBusinesses from "./Slices/allBusinessessSlice";
import categories from "./Slices/categoriesSlice";
import category from "./Slices/categorySlice";
import location from "./Slices/locationSlice";
import loading from "./Slices/loadingSlice";

const store = configureStore({
    reducer: {
        user,
        toast,
        allBusinesses,
        selectedBusiness,
        businessProfiles,
        businessProfile,
        categories,
        category,
        location,
        loading,
    },
});

export default store;
