import React from 'react'
import { Link } from 'react-router-dom'

function Cancel() {
  return (
    <div>
      <h2>Oops!! Cancelled your payment..</h2>
      <Link to="/">
        Return to home page..
      </Link>
    </div>
  )
}

export default Cancel