import axios from "axios";
export const calcBPScore = (b) => {
    if (!b || Object.keys(b).length === 0) {
        return 30; // Return 30 for empty objects
    }
    let value = 30;
    if (b?.businessLogo) {
        value += 7.7;
    }
    if (b.businessCard && b.businessCard !== "") {
        value += 7.7;
    }
    if (b.timing !== "") {
        value += 7.7;
    }
    if (b.catalogue?.length !== 0 && b.catalogue?.length) {
        value += 7.7;
    }
    if (b.contact?.mobile1) {
        value += 7.7;
    }
    if (b.offer?.length !== 0) {
        value += 7.7;
    }
    if (b.photos?.length !== 0) {
        value += 7.7;
    }
    if (b.reviews?.length > 0) {
        value += 7.7;
    }
    if (b.socialMediaLinks?.length !== 0) {
        value += 7.7;
    }
    if (b.videoUrl !== "") {
        value += 7.7;
    }
    if (b.website?.length !== 0) {
        value += 7.7;
    }
    return Math.floor(value);
};

const GEOCODING_API_URL = "https://api.opencagedata.com/geocode/v1/json";
const API_KEY = "bf0f1fdcda7e42e8a5398d74dd6a27ce"; // Replace with your OpenCage API key

export const getCoordinates = async (address) => {
    try {
        const response = await axios.get(GEOCODING_API_URL, {
            params: {
                q: address,
                key: API_KEY,
            },
        });
        const { results } = response.data;
        if (results.length > 0) {
            const { geometry } = results[0];
            return {
                latitude: geometry.lat,
                longitude: geometry.lng,
            };
        }
        return null;
    } catch (error) {
        console.error("Error fetching coordinates:", error);
        return null;
    }
};
