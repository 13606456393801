import React, { useState } from "react";
import hed from "../../../Assets/banar.png"
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./Hero.css";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);


  return (
    <div className="container">
      <div className="d-flex flex-column flex-md-row justify-content-md-between head">
        <div className="head-text">
          <h2 className="text-top-line">
            Hire <span>Experts</span> &
          </h2>
          <h2 className="text-bot-line">Get Your Job Done</h2>
          <form >
            <div className="search-box">
              <div className="row1 " onClick={() => { navigate("/searchBusiness") }} >
                <button type="button" className="search-btn-2" >
                  <LocationOnIcon className="search-btn-icon" />
                </button>
                <input
                  type="text"
                  className="search-input-1"
                  value="Select location"
                  readOnly
                />
                <input
                  type="text"
                  id="input-box"
                  placeholder="Search Services "
                  autoComplete="off"
                  className="search-input"
                  value="Search Services "
                  readOnly
                />
                <button type="submit" className="search-btn-1">
                  <SearchIcon className="search-btn-icon" />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="header-image">
          <img src={hed} alt="header-product" />
        </div>
      </div>
    </div >
  );
}

export default Hero;
