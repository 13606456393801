import React, { useState } from 'react';
import './AboutServices.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AboutServices = () => {
  return (
    <>
      <div className='about-service'>
        <h2 className='about-service-title'>Service</h2>
      </div>
      <div className='about-service-card'>
        <div className="container mt-5">
          <div className="scard p-2">
            <div className="scard-body">
              <ServiceOption price={124} />
              <ServiceOption price={124} />
              <ServiceOption price={12} isHourly />
              <ServiceOption price={10} isHourly />
              <div className='d-flex justify-content-between align-items-center mt-3'>
                <div className="btn-group" role="group" aria-label="Service control buttons">
                  <button type="button" className="btn btn-primary"><KeyboardArrowUpIcon /></button>
                  <button type="button" className="btn btn-primary"><KeyboardArrowDownIcon /></button>
                </div>
                <button className="btn btn-primary">CONTINUE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ServiceOption = ({ price, isHourly }) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  return (
    <div className="about-service-option d-flex justify-content-between align-items-center mb-3">
      <div>
        <span className="about-service-title">3 bedroom or a house </span>
        <span className="badge bg-secondary">OFFER</span>
        <p className="about-service-description">Description about the service.</p>
      </div>
      <div className="d-flex align-items-center">
        <span className="about-service-price">
          ${price} {isHourly ? "/Hour" : ""}
        </span>
        {isHourly && (
          <div className="quantity-controls d-flex align-items-center ml-3">
            <button className="btn btn-light" onClick={handleDecrease}>-</button>
            <input
              type="text"
              className="form-control mx-2"
              value={quantity}
              readOnly
            />
            <button className="btn btn-light" onClick={handleIncrease}>+</button>
          </div>
        )}
        <button className="btn btn-warning ms-3">SCHEDULE</button>
      </div>
    </div>
  );
}

export default AboutServices;
