import { createSlice } from "@reduxjs/toolkit";

let initialState = {};
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        },
        resetUser: () => {
            return initialState;
        },
    },
});

export default userSlice.reducer;
export const { setUser, resetUser } = userSlice.actions;
