import { Card, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useSelector } from "react-redux";


function Reviews() {
    const businessProfile = useSelector(store => store?.businessProfile)
    console.log(businessProfile)
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<p key={i}>⭐</p>);
        }
        return stars;
    };
    const convertToIST = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'Asia/Kolkata', timeZoneName: 'short' };
        return date.toLocaleDateString('en-US', options);
    }
    return (
        <div className="container">
            <div className="ms-5 me-5">

                <Card className="">
                    <div className="name-heding">
                        <div className="d-flex ">
                            <Link to="/businessDashboard">
                                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                            </Link>
                            <h5 className="business-heding">All Reviews</h5>
                        </div>
                    </div>
                    <div className="conten">
                        <div className="">
                            <div className="Rating-reviews">
                                <div className="garp container w-75" style={{ margin: "5px auto", }} >
                                    {[
                                        { progress: "15", value: 1 },
                                        { progress: "25", value: 2 },
                                        { progress: "50", value: 3 },
                                        { progress: "75", value: 4 },
                                        { progress: "100", value: 5 },
                                    ].map((obj, index) => (
                                        <div className="d-flex" key={index} >
                                            <div className="progress " style={{ margin: "5px 0", width: "100%" }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: obj.progress + "%" }} aria aria-valuenow={obj.progress} aria-valuemin="0" aria-valuemax="100">{businessProfile?.reviews?.length > 0 && businessProfile?.reviews?.filter(q => q?.rating === obj?.value)?.length}</div>
                                            </div>
                                            <p style={{ fontWeight: "bold" }} >
                                                &nbsp;{obj.value}
                                            </p>
                                        </div>
                                    ))}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="d-flex">
                                                <p style={{ fontSize: "24px" }} >Average rating :
                                                    {businessProfile?.reviews?.length > 0 &&
                                                        <>
                                                            &nbsp;
                                                            {(
                                                                businessProfile.reviews.reduce((sum, obj) => sum + obj.rating, 0) / businessProfile.reviews.length
                                                            ).toFixed(0)}
                                                            &nbsp;out of 5(⭐).
                                                        </>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {businessProfile?.reviews?.length === 0 && <>
                                <h3 style={{ textTransform: "none" }}>No customer reviewed till now.</h3>
                            </>}
                            <div classNameName="com-reviews">
                                {businessProfile?.reviews?.map((review, index) => (
                                    <div className="com-row" key={index}>
                                        <Card className=" p-3 m-3">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <div className="d-flex align-items-center ">
                                                    <div className="p-2">
                                                        <Avatar style={{ width: "60px", height: "60px", fontSize: "24px", backgroundColor: "indigo", color: "#fff" }}>
                                                            {review?.user?.userName?.charAt(0).toUpperCase()}
                                                        </Avatar>
                                                    </div>

                                                    <div className="pt-2 " >
                                                        <p style={{ color: 'darkblue', fontSize: "20px" }} >{review?.user?.userName}</p>
                                                        <br />
                                                        <p style={{ fontSize: "18px" }} >{review?.comments[0]?.body}</p>
                                                        <br />
                                                        <p>
                                                            <span style={{ display: "inline-block", fontWeight: "900", color: 'black', fontSize: "16px" }}>
                                                                Rated :
                                                            </span>
                                                            &nbsp;
                                                            {review?.rating}
                                                            {renderStars(review?.rating)}
                                                        </p>
                                                        <div style={{ color: 'gray' }}>
                                                            Commented on :
                                                            {
                                                                convertToIST(review?.comments[0]?.commentedOn)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Reviews;