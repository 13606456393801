import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import DoneIcon from "@mui/icons-material/Done";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import "./Vendor.css";
import vendor1 from "../../../Assets/1 (1).jpg";
import vendor2 from "../../../Assets/2 (2).jpg";
import vendor3 from "../../../Assets/3 (2).jpg";

function Vendor() {
  return (
    <div className="vender">
      <div class="container">
        <div class="row p-5">
          <div class="col ">
            <p className="ser-text">VENDOR</p>
            <h2 className="ser-head">Featured Providers</h2>
          </div>
          <div class="col">
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              usmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col ">
            <div className="card" style={{ height: "630px" }}>
              <div className="card-body">
                <div className="card-tital">
                  <div className="card-text">
                    <h4 className="card-t" style={{fontSize:"20px" ,fontWeight:"bold"}}>Javier Bardem</h4>
                    <p className="card-font" style={{marginBottom:"5px",fontSize:"20px" ,fontWeight:"bold"}}>Queens, United States</p>
                    <div className="check-icon">
                      <div className="icon-fav">
                        <FavoriteBorderIcon />
                      </div>
                      <div className="check d-flex align-items-center justify-content- ">
                        <DoneIcon style={{marginRight:"5px",marginBottom:"5px" , fontSize:"20px", color:"#fff"}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-img">
                  <img src={vendor1} alt="img" className="card-img-po" />
                </div>
                <div className="card-p">
                  <p>
                    Through our expertise, technological
                    <br /> knowledge, global presence and
                    <br /> bespoke.
                  </p>
                </div>
                <div className="card-star">
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div>
                    <StarIcon />
                  </div>
                </div>
                <div className="card-btn">
                  <button className="card-btn-1" type="submit">
                    Request A Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ height: "630px", }}>
              <div className="card-body">
                <div className="card-tital">
                  <div className="card-text">
                    <h4 className="card-t" style={{fontSize:"20px",fontWeight:"bold"}}>Javier Bardem</h4>
                    <p className="card-font" style={{marginBottom:"5px",fontSize:"20px" ,fontWeight:"bold"}}>Queens, United States</p>
                    <div className="check-icon">
                      <div className="icon-fav">
                        <FavoriteBorderIcon />
                      </div>
                      <div className="check">
                        <DoneIcon style={{marginRight:"5px",marginBottom:"5px" , fontSize:"20px", color:"#fff"}}  />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-img">
                  <img src={vendor2} alt="img" className="card-img-po" />
                </div>
                <div className="card-p">
                  <p>
                    Through our expertise, technological
                    <br /> knowledge, global presence and
                    <br /> bespoke.
                  </p>
                </div>
                <div className="card-star">
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div>
                    <StarIcon />
                  </div>
                </div>
                <div className="card-btn">
                  <button className="card-btn-1" type="submit">
                    Request A Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ height: "630px" }}>
              <div className="card-body">
                <div className="card-tital">
                  <div className="card-text">
                    <h4 className="card-t" style={{fontSize:"20px",fontWeight:"bold"}}>Javier Bardem</h4>
                    <p className="card-font" style={{marginBottom:"5px",fontSize:"20px" ,fontWeight:"bold"}}>Queens, United States</p>
                    <div className="check-icon">
                      <div className="icon-fav">
                        <FavoriteBorderIcon />
                      </div>
                      <div className="check">
                        <DoneIcon  style={{marginRight:"5px",marginBottom:"5px" , fontSize:"20px", color:"#fff"}} />
                      </div> 
                    </div>
                  </div>
                </div>
                <div className="card-img">
                  <img src={vendor3} alt="img" className="card-img-po" />
                </div>
                <div className="card-p">
                  <p>
                    Through our expertise, technological
                    <br /> knowledge, global presence and
                    <br /> bespoke.
                  </p>
                </div>
                <div className="card-star">
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div className="star-color">
                    <StarIcon />
                  </div>
                  <div>
                    <StarIcon />
                  </div>
                </div>
                <div className="card-btn">
                  <button className="card-btn-1" type="submit">
                    Request A Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-arrow">
          <button className="arrow-1" type="button">
            <WestIcon />
          </button>
          <button className="arrow-1" type="button">
            <EastIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Vendor;
