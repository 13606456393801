import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
const businessProfilesSlice = createSlice({
    name: "businessProfiles",
    initialState,
    reducers: {
        setBusinessProfiles: (state, action) => {
            return action.payload;
        },
        resetBusinessProfiles: () => {
            return initialState;
        },
    },
});
export const { setBusinessProfiles, resetBusinessProfiles } =
    businessProfilesSlice.actions;
    
export default businessProfilesSlice.reducer;
