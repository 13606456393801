import React, {  useState } from "react";
import "./Navbar.css";
import logo from "../../../Assets/logo.png"
import AddIcon from "@mui/icons-material/Add";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import StarsIcon from '@mui/icons-material/Stars';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "../../../Store/Slices/userSlice";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { showToast } from "../../../Store/Slices/toastSlice";
function Navbar() {
  const user = useSelector(store => store.user);

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const userName = location.state?.userName;
  const plan = location.state?.plan;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleBusiness = () => {
    navigate("/register", { state: { userName, plan } });
  };
  const handleLogout = () => {
    dispatch(resetUser());
    dispatch(showToast({ type: 'success', message: "Logged out successfully.", show: true }))
  };
  console.log(user)
  return (
    <div className="nav1">
      <nav className="navbar navbar-expand-sm navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Pages
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/about-us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/categories">
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/search">
                      Search
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/profile">
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/jobs">
                  Jobs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>

            <form className="d-flex">
              {user?.package && (
                <div className="p-2">
                  <button type="button" className="btn btn-warning membership d-flex align-items-center justify-content-center">
                    <StarsIcon />
                    Membership
                  </button>
                </div>
              )}
              <div className="p-2" onMouseLeave={() => setIsOpen(false)}>
                {user?.userName ? (
                  <div className="a">
                    <button type="button" className="btn btn-warning d-flex align-items-center justify-content-center" onMouseOver={() => setIsOpen(true)}>
                      <PermIdentityIcon style={{ fontSize: 18.75 }} />
                      {user?.userName}
                    </button>
                    {isOpen && (
                      <div className="dd  mw-100" onClick={handleLogout}>
                        <button className="dropdown-item " >Log out</button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to="/login">
                    <button type="button" className="btn btn-warning" style={{paddingRight:"17px",color:"#fff"}}>
                      <PermIdentityIcon style={{ fontSize: 18.75,color:"#FFF", marginBottom:"4px",marginRight:"3px"}} />
                      Login
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-2 d-flex align-items-center justify-content-center">
                {user?.business?.length === 0 || !user?._id ?
                  <button type="button" className="btn btn-warning" onClick={handleBusiness} style={{paddingRight:"17px",color:"#fff"}}>
                    <AddIcon style={{ fontSize: 18.75,color:"#FFF", marginBottom:"4px",marginRight:"3px"}}/>
                    Add Listing
                  </button> : <button type="button" className="btn btn-warning d-flex align-items-center justify-content-center" onClick={() => {
                    navigate("/allBusinesses")
                  }}>
                    <BusinessCenterIcon style={{ fontSize: 18.75 }} />
                    My Business
                  </button>}
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
