import React, { useState } from "react";
import "./AddReview.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../../Store/Slices/toastSlice";
import backendHost from "../../../Api";
import { setBusinessProfile } from "../../../Store/Slices/businessProfileSlice";
const AddReview = () => {
    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);
    const user = useSelector(store => store?.user)
    const selectedBusiness = useSelector(store => store?.selectedBusiness)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!user?.email) {
            dispatch(showToast({ type: 'error', message: "Please login to add a review." }))
            navigate("/login", { state: { from: location?.pathname } });
        } else {
            console.log("Submitted:", { comment, rating });
            const data = {
                comment,
                rating,
                user: user?._id,
                business: selectedBusiness?._id
            }

            backendHost.post("/businessProfile/addReview", data)
                .then(res => {
                    console.log(res.data);
                    dispatch(setBusinessProfile(res?.data?.businessProfile))
                    navigate("/searchBusiness")
                    dispatch(showToast({ type: 'success', message: res?.data?.message }))
                    setComment("");
                    setRating(0);
                })
                .catch(error => {
                    console.error("Error adding review:", error);
                });
        }
    };
    return (
        <>
            <div className="service">
                <h2 className="">Add a Review</h2>
            </div>
            <div className="service-card">
                <form className="request border p-4" onSubmit={handleSubmit}>

                    <div className="input-form">
                        <label>Rating</label>
                        <input
                            type="number"
                            className="input-01 border"
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                            min="1"
                            max="5"
                            required
                        />
                    </div>
                    <div className="input-form">
                        <label>Comment</label>
                        <textarea
                            className="textarea form-control"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            rows="4"
                            required
                        ></textarea>
                    </div>
                    <div className="d-flex justify-content-center p-4">
                        <button
                            type="submit"
                            className="btn btn-warning text-light p-3"
                        >
                            Submit Review
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddReview;

