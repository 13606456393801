import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BusinessRegisterForm.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import backendHost from "../../Api";
import { State, City } from "country-state-city"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../Store/Slices/userSlice";
import { showToast } from "../../Store/Slices/toastSlice";

function BusinessRegisterForm({ type }) {
  console.log(type)
  const { selectedBusiness } = useSelector(store => store.user)
  console.log(selectedBusiness)
  function getStateIsoCode(countryCode, stateName) {
    const states = State.getStatesOfCountry(countryCode);
    const state = states.find(s => s.name.toLowerCase() === stateName.toLowerCase());
    return state ? state.isoCode : null;
  }

  const user = useSelector(store => store.user)
  const navigate = useNavigate()
  const [businessName, setBusinessName] = useState(type === "Edit" ? selectedBusiness?.businessName : "");
  const [businessNumber, setBusinessNumber] = useState(type === "Edit" ? selectedBusiness?.businessNumber : "");
  const [address, setAddress] = useState(type === "Edit" ? selectedBusiness?.address : "");
  const [category, setCategory] = useState("--Select Category--");
  const [subCategory, setSubCategory] = useState("--Select Sub Category--");
  const [pin, setPin] = useState("");
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [states, setStates] = useState(type === "Edit" ? selectedBusiness?.state : []);
  const [country, setCountry] = useState(type === "Edit" ? selectedBusiness?.country : "");
  const [ownerFirstName, setOwnerFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [ownerNumber, setOwnerNumber] = useState("");
  const [ownerLastName, setOwnerLastName] = useState("");
  const [language, setLanguage] = useState();
  const [businessLogo, setBusinessLogo] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessDescription, setBusinessDescription] = useState("Enter Business Description here : ");
  const [dynamicCategory, setDynamicCategory] = useState([]);
  const [dynamicSubcategoryMap, setDynamicSubcategoryMap] = useState({});

  console.log(state)
  useEffect(() => {
    const fetchStates = () => {
      if (!user?._id) {
        navigate("/login")
      }
      if (country === "United States") {
        setStates(State.getStatesOfCountry("US").map(e => e.name));
      } else if (country === "Canada") {
        setStates(State.getStatesOfCountry("CA").map(e => e.name));
      } else {
        setStates([]);
      }
    };

    fetchStates();
  }, [country]);

  // Updating cities as per the country name
  useEffect(() => {
    const fetchCities = () => {
      if (typeof state === 'string') {
        let countryCode = country === "Canada" ? "CA" : "US";
        let stateCode = getStateIsoCode(countryCode, state)
        console.log(countryCode, stateCode)
        let cities = City.getCitiesOfState(countryCode, stateCode)
        setCity(cities.map(c => c.name))
        console.log(cities)
      }
    };

    fetchCities();
  }, [state, country]);

  // FETCHING CATEGORIES
  useEffect(() => {
    const fetchCategories = async () => {
      backendHost.get(`/business/getCategoriesgetSubCategories`)
        .then(res => {
          let categoryNames = res?.data?.categories.map(item => (item.name))
          setDynamicCategory(categoryNames);
          setDynamicSubcategoryMap(res.data.categorySubcategoryMap);
        })
        .catch(err => console.log(err?.message))
    };
    fetchCategories();
  }, [])
  const dispatch = useDispatch()

  const location = useLocation();
  const userName = location.state?.userName;
  const plan = location.state?.plan;

  function submit(e) {
    try {
      e.preventDefault();
      let formData = new FormData();
      formData.append("businessName", businessName);
      formData.append("businessNumber", businessNumber);
      formData.append("address", address);
      formData.append("businessEmail", businessEmail);
      formData.append("city", city);
      formData.append("category", category);
      formData.append("subCategory", subCategory);
      formData.append("pin", pin);
      formData.append("state", state);
      formData.append("country", country);
      formData.append("ownerFirstName", ownerFirstName);
      formData.append("email", email);
      formData.append("ownerNumber", ownerNumber);
      formData.append("ownerLastName", ownerLastName);
      formData.append("language", language);
      formData.append("businessLogo", businessLogo);
      formData.append("userName", userName);
      formData.append("plan", plan);
      formData.append("description", businessDescription)
      backendHost
        .post(`/business/register/${user?._id}`, formData)
        .then((res) => {
          if (res.data.success) {
            dispatch(showToast({ type: "success", message: res?.data?.message }))
            dispatch(setUser(res?.data?.user))
            navigate("/allBusinesses")
          }
        })
        .catch((err) => {
          dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }))
          console.log(err)
        });

    } catch (error) {
      console.log(error)
      dispatch(showToast({ type: 'error', message: error?.message }))
    }
  }
  return (
    <div className="container mt-0" >
      <div className="w-0 allBusinessParent mt-4">
        <Link
          to={type === "Edit" ? "/businessDashboard" : "/"}
          className=" text-left d-flex align-items-center text-decoration-none"
        >
          <ArrowBackIcon className="me-0" />
          {type === "Edit" ? <>Back to Dashboard</> : <>Back to Home</>}

        </Link>
      </div>
      <div className="register-1 mt-4">
        <div className="business-info">
          <h2 className="bus-title">Business Information</h2>
          <hr style={{ width: "100%", padding: "2px" }} />
          <form className="business-info-1 row g-3" onSubmit={submit}>
            <div className="col-md-6">
              <input
                required
                type="text"
                className="form-control"
                name="businessName"
                placeholder="Business Name*"
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
                value={type === "Edit" ? businessName : businessName}
              />
            </div>
            <div className="col-md-6">
              <input
                required
                type="text"
                className="form-control"
                name="businessNumber"
                placeholder="Business Phone Number*"
                onChange={(e) => {
                  setBusinessNumber(e.target.value);
                }}
                value={businessNumber}
              />
            </div>
            <div className="col-md-6">
              <input
                required
                type="text"
                className="form-control"
                name="address"
                placeholder="Business Street Address*"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
              />
            </div>
            <div className="col-md-6">
              <select
                className="form-select"
                name="country"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                {type === "Edit" ?
                  <>
                    <option selected value={selectedBusiness.country}>{selectedBusiness.country}</option>
                    <option selected value={selectedBusiness.country === "Canada" ? "United States" : "Canada"}>{selectedBusiness.country === "Canada" ? "United States" : "Canada"}</option>
                  </>
                  : <>
                    <option disabled selected >--Select Country--</option>
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                  </>}
              </select>
            </div>
            <div className="col-md-6">
              <select
                className="form-select"
                name="state"
                onChange={(e) => {
                  setState(e.target.value);
                }}
                value={state}
              >
                <option disabled selected >--Select State---</option>
                {/* {typeof state === "string" ? <optio></optio> :
                  states?.map((state, ind) => (
                    <option key={ind} value={state}>
                    {state}
                  </option>
                ))} */}
                {states?.map((state, ind) => (
                  <option key={ind} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <select
                className="form-select"
                name="state"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                value={city}
              >
                <option disabled selected >--Select City---</option>
                {typeof city === "string" ? <option>{city}</option> :
                  // city?.length > 0 ?
                  city?.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))
                  // : <option disabled>No cities to select
                  //   {console.log("0 CITY.")}
                  // </option>
                }
              </select>
            </div>
            <div className="col-md-6">
              <input

                type="file"
                className="form-control"
                aria-label=""
                name="businessLogo"
                onChange={(e) => {
                  setBusinessLogo(e.target.files[0]);
                }}
                placeholder="Push business logo file"
              />
            </div>
            <div className="col-md-6">
              <input
                required
                type="email"
                className="form-control"
                name="businessEmail"
                placeholder="Business Email*"
                onChange={(e) => {
                  setBusinessEmail(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  console.log("Selected category:", e.target.value);
                }}
                className="form-select"
              >
                <option selected disabled>
                  --Select Category--
                </option>
                {dynamicCategory?.map((cate) => (
                  <option key={cate} value={cate}>
                    {cate}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <select
                className="form-select"
                name="subCategory"
                onChange={(e) => {
                  setSubCategory(e.target.value);
                }}
                value={subCategory}
              >
                <option disabled={true} >--Select Sub Category--</option>
                {category &&
                  dynamicSubcategoryMap[category] &&
                  dynamicSubcategoryMap[category].map((subCate) => (
                    <option key={subCate} value={subCate}>
                      {subCate}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-md-6">
              <input
                required
                type="text"
                className="form-control"
                name="pin"
                placeholder="Postal Code*"
                onChange={(e) => {
                  setPin(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <textarea style={{ width: "100%", resize: "none", marginTop: "14px", padding: "5px", boder: "1px solid lightgray", color: `${businessDescription === "Enter Business Description here :  " ? "lightgray" : "black"}` }} onClick={() => {
                if (businessDescription === "Enter Business Description here :  ") {
                  setBusinessDescription("");
                }
              }} rows={1} onChange={(e) => setBusinessDescription(e.target.value)} name="" id="">{businessDescription}</textarea>
            </div>
            <div className="contact-info">
              <h2 className="bus-title">Contact Information</h2>
              <hr style={{ width: "100%", padding: "2px" }} />

              <div className="row g-3">
                <div className="col-md-6">
                  <input
                    required={true}
                    type="text"
                    className="form-control"
                    name="ownerFirstName"
                    placeholder="First Name*"
                    onChange={(e) => {
                      setOwnerFirstName(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <input
                    required={true}
                    type="text"
                    className="form-control"
                    name="ownerLastName"
                    placeholder="Last Name*"
                    onChange={(e) => {
                      setOwnerLastName(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <input
                    required={true}
                    type="text"
                    className="form-control"
                    name="ownerNumber"
                    placeholder="Personal Phone Number*"
                    onChange={(e) => {
                      setOwnerNumber(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    required={true}
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email Address*"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    name="language"
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}
                    value={language}
                  >
                    <option value="English">English</option>
                    <option value="French">French</option>
                  </select>
                </div>

              </div>
            </div>
            <div className="col-12 d-flex justify-content-center mt-4">
              <button type="submit" className="btn btn-primary">Register</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BusinessRegisterForm;
