import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const locationSlice = createSlice({
    initialState,
    name: "location",
    reducers: {
        setLocation: (_, { payload }) => payload,
        resetLocation: () => initialState,
    },
});
export const { setLocation, resetLocation } = locationSlice.actions;

export default locationSlice.reducer;