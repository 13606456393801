
import React, { useEffect } from "react";
import "./Categories.css";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../../Api";
import { setCategories } from "../../../Store/Slices/categoriesSlice";
import { showToast } from "../../../Store/Slices/toastSlice";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(store => store?.categories || []);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      backendHost.get("/category/getCategories&Subcategories&BusinessLists")
        .then(res => {
          console.log(res?.data)
          dispatch(setCategories(res?.data?.categories));
        })
        .catch(err => {
          console.log(err)
          dispatch(showToast({ type: "error", message: err?.response?.data?.message }))
        })
    } catch (error) {
      console.log(error)
      dispatch(showToast({ type: 'error', message: error?.message, show: true }))
    }
  }, [dispatch]);

  return (
    <div className="services">
      <div className="container">
        <div className="row p-5">
          <div className="col ">
            <p className="ser-text">CATEGORIES</p>
            <h2 className="ser-head">Popular Categories</h2>
          </div>
          <div className="col">
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {categories?.slice(0, 9)?.map((category, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index} onClick={() => { navigate(`/categoryDetails/${category?.category?._id}`) }} >
              <div className="media-bg-animate mba-bdr-15">
                <div className="aon-categories-area2-iconbox aon-icon-effect">
                  <div className="aon-cate-area2-icon">
                    <span>
                      <i className="aon-icon">
                        <img src={category?.category?.logo} alt="" />
                      </i>
                    </span>
                  </div>
                  <div className="aon-cate-area2-content">
                    <h4 className="aon-title">{category.category?.name}
                    </h4>
                    <p>{category.businessCount} Listing{category.businessCount !== 1 && "s"}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ser-btn">
        <button type="button" className="ser-btn-1" onClick={() => navigate("/viewCategories")} style={{backgroundColor:"#ffc107"}} >
          View All
        </button>
      </div>
    </div>
  );
}

export default Categories;
