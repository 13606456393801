import React from "react";
import "./Home.css";
import Hero from "../../Components/home/Hero/Hero";
import Categories from "../../Components/home/Categories/Categories";
import Work from "../../Components/home/work/Work";
import Vendor from "../../Components/home/vendor/Vendor";
import Sec1 from "../../Components/home/section1/Sec1";
import Sec2 from "../../Components/home/section2/Sec2";
import Sec3 from "../../Components/home/section3/Sec3";
import Sec4 from "../../Components/home/section4/Sec4";
// import Sec5 from "../Sec5";
import Sec6 from "../../Components/home/section6/Sec6";
import Footer from "../../Components/home/footer/Footer";
import Navbar from "../../Components/home/navbar/Navbar";

function Home() {


  return (
    <div>
      <Navbar />
      <Hero />
      <Categories />
      <Work />
      <Vendor />
      <Sec1 />
      <Sec2 />
      <Sec3 />
      <Sec4 />
      {/* <Sec5 /> */}
      <Sec6 />
      <Footer />
    </div>
  );
}

export default Home;
