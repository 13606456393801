import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import "./Login.css"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../Store/Slices/userSlice";
import backendHost from "../../Api";
import { showToast } from "../../Store/Slices/toastSlice";


const Signup = () => {
    const navigate = useNavigate();

    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const user = useSelector(store => store.user)
    console.log(user)

    function submit(e) {
        e.preventDefault();
        try {
            backendHost.post("/user/signup", {
                userName, email, password
            })
                .then(res => {
                    if (res?.data?.success) {
                        dispatch(setUser(res.data.user))
                        navigate("/")
                        dispatch(showToast({ message: res?.data?.message, type: 'success' }))
                    }
                })
                .catch(e => dispatch(showToast({ message: e?.response?.data?.message, type: "error" })))
        }
        catch (e) {
            console.log(e);
            dispatch(showToast({ message: e?.message, type: "error" }))
        }

    }


    return (
        <div className="containers">
            <input type="checkbox" id="check" />
            <div className="login form">
                <header className="login-1">Sign-Up</header>
                <form action="POST">
                    <input type="text" onChange={(e) => { setUserName(e.target.value) }} placeholder="Enter your Full Name" />
                    <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter your email" />
                    <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Enter your password" />
                    <input type="button" className="button" value="Sign-Up" onClick={submit} />
                </form>
                <div className="signup">
                    <span className="signup">Already have an account?
                        <Link to="/login">Login</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Signup