import React, { useEffect } from 'react';
import './Reviews.css';
import { useSelector } from 'react-redux';

const ReviewsandComments = () => {
  const businessProfile = useSelector(store => store?.businessProfile);
  const reviews = businessProfile?.reviews && businessProfile?.reviews?.length > 0
    ? [...businessProfile.reviews]
      .sort((a, b) => b?.rating - a?.rating)
      .slice(0, 3)
    : [];
  console.log(reviews)
  // useEffect(() => { }, [businessProfile])
  return (
    <>
      <div className='reviews-comments-service'>
        <h2>Customer Reviews</h2>
      </div>
      {reviews?.length > 0 ? (
        reviews?.map((review, index) => (
          <div key={index} className='reviews-comments-service-card'>
            <div className='reviews-comments-card'>
              <div className='reviews-comments-header'>
                <div className='reviews-comments-avatar'>
                  {review?.user?.userName && review?.user?.userName[0]?.toUpperCase()}
                </div>
                <h3 className='reviews-comments-user-name'>
                  {review?.user?.userName || 'Anonymous'}
                </h3>
              </div>
              <div className='reviews-comments-text'>
                {review?.comments && review?.comments?.length > 0 ? (
                  review?.comments?.map((comment, idx) => (
                    <p key={idx}>{comment.body}</p>
                  ))
                ) : (
                  <p>No comments available</p>
                )}
                <br />
                <p className='reviews-comments-rating'>Rating: {review?.rating}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='reviews-comments-service-card'>
          <p>No reviews available.</p>
          </div>
      )}
    </>
  );
};

export default ReviewsandComments;
