import React, { useState } from "react";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import "./AddCatalogue.css"; // Assuming you have a CSS file for custom styles
import backendHost from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

function AddCatalogue() {
    const businessProfile = useSelector(store => store.businessProfile);
    const selectedBusiness = useSelector(store => store.selectedBusiness);
    const [file, setFile] = useState(null);
    const [name, setName] = useState(businessProfile?.catalogue?.length > 0 ? businessProfile?.catalogue[businessProfile?.catalogue?.length - 1]?.name : "");
    const [description, setDescription] = useState(businessProfile?.catalogue?.length > 0 ? businessProfile?.catalogue[businessProfile?.catalogue?.length - 1]?.description : "");
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) 
            setFile(event.target.files[0]);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            let fd = new FormData();
            fd.append("catalogue", file);
            fd.append("name", name);
            fd.append("description", description);
            backendHost.post(`/businessProfile/addCatalogue/${selectedBusiness?._id}`, fd)
                .then(res => {
                    dispatch(showToast({ message: res?.data?.message, type: 'success' }))
                    dispatch(setBusinessProfile(res?.data?.businessProfile))
                    navigate("/businessDashboard")
                })
                .catch(err => {
                    console.log(err)
                    dispatch(showToast({ type: 'error', message: err?.response?.data?.message }))
                });
        } catch (error) {
            console.error("Error while submitting form:", error);
            dispatch(showToast({ type: 'error', message: error?.message }))
        }
    };

    return (
        <div className="container my-0">
            <Card className="p-4 shadow-sm addCatalogueCard">
                <div className="d-flex align-items-center mb-1 addCataloguHeading ">
                    <Link to="/businessDashboard" className="text-dark me-2">
                        <ArrowLeftIcon className="fs-2" />
                    </Link>
                    <h5 className="mb-0">Add Catalogue</h5>
                </div>
                <p className="text-secondary mt-5">
                    Save and update your catalogue details to stay in touch with your customers in real time.
                </p>
                <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="form-label">Catalogue Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter catalogue name"
                            value={name}
                            onChange={(e) => setName(e.target.value)} // Corrected here
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Catalogue Description</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter catalogue description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)} // Corrected here
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Upload Catalogue</label>
                        <input
                            type="file"
                            className="form-control"
                            accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.svg,.zip,.rar"
                            onChange={(e) => handleImageChange(e)}
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-warning text-white" style={{ width: "200px" }}>
                            SAVE
                        </button>
                    </div>
                </form>
            </Card>
        </div>
    );
}

export default AddCatalogue;
