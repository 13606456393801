import React, { useState, useEffect } from 'react';
import { LocationOn, Star, CheckCircle, Business, FavoriteBorder } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedBusiness } from '../../Store/Slices/selectedBusinessSlice';
import { setBusinessProfile } from '../../Store/Slices/businessProfileSlice';
import { getCoordinates } from '../../Contants';
import { getDistance } from 'geolib';

const SearchMain = () => {
    const businesses = useSelector((store) => store?.allBusinesses?.filtered);
    const userLocation = useSelector((store) => store?.location);
    const businessProfiles = useSelector(store => store?.businessProfiles);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [businessesPerPage, setBusinessesPerPage] = useState(5);
    const [sortedBusinesses, setSortedBusinesses] = useState([]);

    useEffect(() => {
        const fetchCoordinatesAndSort = async () => {
            try {
                if (businesses && businesses.length > 0) {
                    const businessesWithCoordinates = await Promise.all(businesses.map(async business => {
                        const address = `${business.address}, ${business.city}, ${business.state}, ${business.country}`;
                        const coordinates = await getCoordinates(address);
                        console.log(`Coordinates for ${business.businessName}:`, coordinates);
                        return {
                            ...business,
                            latitude: coordinates?.latitude,
                            longitude: coordinates?.longitude
                        };
                    }));

                    if (userLocation && businessesWithCoordinates.length > 0) {
                        const businessesWithDistance = businessesWithCoordinates.map(business => {
                            if (business.latitude && business.longitude && userLocation.latitude && userLocation.longitude) {
                                return {
                                    ...business,
                                    distance: getDistance(
                                        { latitude: userLocation.latitude, longitude: userLocation.longitude },
                                        { latitude: business.latitude, longitude: business.longitude }
                                    )
                                };
                            } else {
                                return {
                                    ...business,
                                    distance: Infinity
                                };
                            }
                        });

                        const sorted = businessesWithDistance.sort((a, b) => a.distance - b.distance);
                        setSortedBusinesses(sorted);
                    } else {
                        setSortedBusinesses(businessesWithCoordinates);
                    }
                }
            } catch (error) {
                console.error('Error fetching coordinates and sorting:', error);
                setSortedBusinesses(businesses);
            }
        };

        fetchCoordinatesAndSort();
    }, [userLocation, businesses]);

    const indexOfLastBusiness = currentPage * businessesPerPage;
    const indexOfFirstBusiness = indexOfLastBusiness - businessesPerPage;
    const currentBusinesses = sortedBusinesses.slice(indexOfFirstBusiness, indexOfLastBusiness);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(sortedBusinesses.length / businessesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="search-cart col-md-9 col-sm-12 searchMain">
            <div className="search-cart-main d-flex justify-content-between align-items-center">
                <div className="search-cart-heading">
                    <h5>{sortedBusinesses.length} results</h5>
                </div>
            </div>
            <div className="row m-4 d-flex">
                {currentBusinesses.map((item, index) => (
                    <div
                        key={index}
                        className="search-card col-md-5 m-2 border"
                        style={{ width: "100%", height: "250px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => {
                            dispatch(setSelectedBusiness(item));
                            const selectedBP = businessProfiles.filter(q => q.bid === item._id)[0];
                            dispatch(setBusinessProfile(selectedBP));
                            navigate(`/business/${item._id}`);
                        }}
                    >
                        <div className="img" style={{ display: 'flex', alignItems: 'center', justifyContent: "cetner" }} >
                            <img
                                src={item.businessLogo}
                                alt={item.businessName}
                                style={{
                                    width: "99%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                    boxShadow: "0 4px 8px 5px rgba(0,0,0,0.1)"

                                }}
                            />
                        </div>
                        <div className="card-body p-3 " style={{ display: "flex", flexDirection: "column", textAlign: 'start', alignItems: 'start' }} >
                            <h3>{item.businessName}</h3>
                            <p>
                                <LocationOn />
                                {`${item.city}, ${item.state}, ${item.country}`}
                            </p>
                            <div className="" >
                                <div className="rating">
                                    {[...Array(5)].map(
                                        (_, index) => (
                                            <Star
                                                key={index}
                                                color={index < item.rating ? "primary" : "disabled"}
                                            />
                                        )
                                    )}
                                </div>
                                <p><Business /> {`${item.subCategory}, ${item.category}`}</p>
                                <div className="icon">
                                    <CheckCircle className="check-1" />
                                    <FavoriteBorder />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {sortedBusinesses.length === 0 && (
                    <div className="text-center mt-4">
                        <p>No businesses found.</p>
                    </div>
                )}
                <div className="pagination-controls mt-3">
                    <button
                        className="btn csButton btn-sm me-2"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        Prev
                    </button>
                    {Array.from({ length: Math.ceil(sortedBusinesses.length / businessesPerPage) }, (_, index) => (
                        <button
                            key={index}
                            className={`btn btn-sm ${currentPage === index + 1 ? 'csButton-selected' : 'csButton'} me-2`}
                            style={{ color: "black" }}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        className="btn btn-sm csButton"
                        onClick={handleNextPage}
                        disabled={currentPage === Math.ceil(sortedBusinesses.length / businessesPerPage)}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchMain;
