import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import backendHost from "../../Api";
import { setUser } from "../../Store/Slices/userSlice";
import { useDispatch } from "react-redux";
import { showToast } from "../../Store/Slices/toastSlice";

function Success() {
    const dispatch = useDispatch();
    const rl = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(rl.search);
        console.log(searchParams);
        const paymentId = searchParams.get("paymentId");
        const payerId = searchParams.get("PayerID");
        const token = searchParams.get("token");
        try {
            backendHost
                .get(
                    `/package/buyPackage-success?paymentId=${paymentId}&PayerId=${payerId}&token=${token}`
                )
                .then((res) => {
                    console.log(res.data);
                    dispatch(setUser(res?.data?.user));
                    dispatch(showToast({ type: 'success', message: res?.data?.message }))
                })
                .catch((error) => {
                    console.error("Error:", error);
                    dispatch(showToast({ type: 'error', message: error?.response?.message }))
                });
        } catch (error) {
            dispatch(showToast({ type: 'error', message: error?.message }))
        }
    }, [dispatch, rl.search]);
    return (
        <div>
            <h2>Thank You for Subscription!!</h2>
            <Link to="/">Click here to return to login page...</Link>
        </div>
    );
}

export default Success;
