import React, { useEffect } from "react";
import "./BusinessInformation.css"
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";

const BusinessInformation = () => {
    const selectedBusiness = useSelector(store => store.selectedBusiness);
    const businessProfile = useSelector(store => store.businessProfile);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    useEffect(() => {
        if (selectedBusiness?._id) {
            backendHost.get(`/businessProfile/getBusinessProfile/${selectedBusiness._id}`)
                .then(res => { dispatch(setBusinessProfile(res?.data?.businessProfile)) })
                .catch(err => { console.log(err) });
        }
    }, [selectedBusiness?._id, dispatch]);
    return (
        <div className="container businessInformationContainer">
            <div className="ms-5 me-5 businessInformationCard">
                {/* <Card className="businessInformationCard"> */}
                <div className="d-flex businessInformationHeader">
                    <Link to="/allBusinesses">
                        <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                    </Link>
                    <h5 className="business-heding">
                        <center>
                            Business Information
                        </center>
                    </h5>
                </div>
                <div className="businessInformationContent">
                    <div className="bus-profile">
                        <div className="profile-img text-center d-flex flex-column justify-content-center align-items-center">
                            {selectedBusiness?.businessLogo?.length > 0 &&
                                <div className="logoContainerBusinessInformation">
                                    <img className="profile-img-pro mt-3" src={selectedBusiness?.businessLogo} alt="profile-img" />
                                </div>
                            }
                            {selectedBusiness?.businessName?.length > 0 &&
                                <h2 className="pt-3">{selectedBusiness?.businessName}</h2>
                            }
                            {selectedBusiness?.description?.length > 0 &&
                                <p className="pt-3">{selectedBusiness?.description}</p>
                            }
                        </div>
                    </div>
                    <div className="bus-info">
                        <div className="bus-info-2 mt-1">
                            {selectedBusiness?.address?.length > 0 &&
                                <div className="row p-2">
                                    <div className="col"><h5 className="row-h5">Business Address:</h5></div>
                                    <div className="col">{selectedBusiness?.address}</div>
                                </div>
                            }
                            {selectedBusiness?.category?.length > 0 &&
                                <div className="row p-2">
                                    <div className="col"><h5 className="row-h5">Business Category:</h5></div>
                                    <div className="col">{selectedBusiness?.category}</div>
                                </div>
                            }
                            {businessProfile?.contact?.mobile1 &&
                                <div className="row p-2">
                                    <div className="col"><h5 className="row-h5">Business Mobile Number 1:</h5></div>
                                    <div className="col">{businessProfile?.contact?.mobile1}</div>
                                </div>
                            }
                            {businessProfile?.contact?.mobile2 &&
                                <div className="row">
                                    <div className="col" style={{ marginLeft: "7px" }}><h5 className="row-h5">Business Mobile Number 2:</h5></div>
                                    <div className="col">{businessProfile?.contact?.mobile2}</div>
                                </div>
                            }
                            {businessProfile?.contact?.email?.length > 0 &&
                                <div className="row p-2">
                                    <div className="col"><h5 className="row-h5">Business Email ID:</h5></div>
                                    <div className="col">{businessProfile?.contact?.email}</div>
                                </div>
                            }
                            {businessProfile?.contact?.whatsApp &&
                                <div className="row p-2">
                                    <div className="col"><h5 className="row-h5">WhatsApp Number:</h5></div>
                                    <div className="col">{businessProfile?.contact?.whatsApp}</div>
                                </div>
                            }
                            {businessProfile?.timing?.days?.length > 0 &&
                                <div className="row p-2">
                                    <div className="col-12">
                                        <h5 className="row-h5">Business Timings:</h5>
                                    </div>
                                    <div className="col">
                                        <table className="timing-table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Time Slots</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {businessProfile?.timing?.days?.map((day, index) => (
                                                    <tr key={day._id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                                        <td>{day.name}</td>
                                                        <td>{day.timeSlots.join(', ')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {businessProfile?.websites?.length > 0 &&
                                <div className=" p-2">
                                    <div className="col"><h5 className="row-h5">Business Websites:</h5></div>
                                    <div className="col ">
                                        <ol className="businessWebsites" style={{ paddingLeft: "15px" }}>
                                            {businessProfile?.websites?.map((w, index) => (
                                                <li key={index} className="businessWebsite">{<Link to={`${w}`} target="_blank">{w}</Link>}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>
                            }
                            {businessProfile?.socialMediaLinks?.length > 0 &&
                                <div className="p-2">
                                    <div className="col"><h5 className="row-h5">Business Social Media Accounts:</h5></div>
                                    <div className="col socialMediaLinks">
                                        {businessProfile?.socialMediaLinks?.map((item, index) => (
                                            <p key={index} className="socialMediaLink" style={{ display: 'flex' }}>
                                                {item?.link?.length > 0 && <>
                                                    <b style={{ color: "black", fontWeight: "bold", fontSize: "1.3vw" }} >{item?.platform?.charAt(0).toUpperCase() + item?.platform?.slice(1)} :&nbsp; </b><Link style={{ padding: "4px 5px" }} to={item?.link} target="_blank" >{item?.link}</Link>
                                                </>}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* </Card> */}
            </div>
        </div>
    );
}

export default BusinessInformation;
