import React, { useEffect, useState } from "react";
import "../../Components/SearchCompos/SearchBusinesses.css";
import Navbar from "../../Components/home/navbar/Navbar";
import backendHost from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Store/Slices/toastSlice";
import { setAllBusinesses } from "../../Store/Slices/allBusinessessSlice";
import SearchSidebar from "../../Components/SearchCompos/SearchSidebar";
import SearchMain from "../../Components/SearchCompos/SearchMain";
import { setBusinessProfiles } from "../../Store/Slices/businessProfilesSlice";
// import {
//     startLoading, endLoading
// } from "../../Store/Slices/loadingSlice";
import { setLocation } from "../../Store/Slices/locationSlice";


const SearchBusinesses = () => {
    const dispatch = useDispatch();
    const userLocation = useSelector((store) => store.location);
    const [locationAccessPrompt, setLocationAccessPrompt] = useState(false);

    useEffect(() => {
        // Fetch all businesses on component mount
        // dispatch(startLoading())
        const fetchBusinesses = async () => {
            try {
                const [businessesRes, profilesRes] = await Promise.all([
                    backendHost.get("/business/getAllBusinesses"),
                    backendHost.get("/businessProfile/getBusinessProfiles"),
                ]);

                dispatch(setAllBusinesses(businessesRes?.data?.businesses));
                dispatch(setBusinessProfiles(profilesRes?.data?.businessProfiles));
            } catch (err) {
                console.log(err);
                dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }));
            }
        };

        const fetchUserLocation = async () => {
            try {
                const permission = await navigator.permissions.query({ name: 'geolocation' });

                if (permission.state === 'granted') {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const { latitude, longitude } = position.coords;
                        dispatch(setLocation({ latitude, longitude }));
                    });
                } else if (permission.state === 'prompt') {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            dispatch(setLocation({ latitude, longitude }));
                        },
                        (error) => {
                            console.error("Error fetching user's location", error);
                            setLocationAccessPrompt(true);
                            dispatch(showToast({ message: 'Unable to fetch location', type: 'error' }));
                        }
                    );
                } else {
                    setLocationAccessPrompt(true);
                }

                permission.onchange = () => {
                    if (permission.state === 'granted') {
                        navigator.geolocation.getCurrentPosition((position) => {
                            const { latitude, longitude } = position.coords;
                            dispatch(setLocation({ latitude, longitude }));
                            setLocationAccessPrompt(false);
                        });
                    } else {
                        setLocationAccessPrompt(true);
                    }
                };
            } catch (error) {
                dispatch(showToast({ message: 'Geolocation is not supported by this browser', type: 'error' }));
            }
        };

        fetchBusinesses();
        fetchUserLocation();
        // dispatch(endLoading())
    }, [dispatch]);

    const requestLocationAccess = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(setLocation({ latitude, longitude }));
                    setLocationAccessPrompt(false);
                },
                (error) => {
                    console.error("Error fetching user's location", error);
                    dispatch(showToast({ message: 'Unable to fetch location', type: 'error' }));
                }
            );
        } else {
            dispatch(showToast({ message: 'Geolocation is not supported by this browser', type: 'error' }));
        }
    };

    return (
        <>
            <Navbar />
            <div className="sidebarContainer">
                <div className="search row searchParent">
                    <SearchSidebar />
                    <SearchMain />
                </div>
            </div>
            {locationAccessPrompt && (
                <div className="location-prompt">
                    <p>Please enable location access to search businesses nearby.</p>
                    <button onClick={requestLocationAccess}>Enable Location Access</button>
                </div>
            )}
        </>
    );
};

export default SearchBusinesses;
