import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./Components/AllRoutes/AllRoutes";
import Toast from "./Components/Toast/Toast";
import LoadingSpinner from "./Components/Common/LoadingSpinner";
function App() {
    return (
        <div className="App">
            <Toast />
            <LoadingSpinner />
            <Router>
                <AllRoutes />
            </Router>
        </div>
    );
}

export default App;
