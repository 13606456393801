import React, { useEffect, useState } from "react";
import "./BusinesMainPage.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Card } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Gauge } from "@mui/x-charts/Gauge";
import { BarChart } from "@mui/x-charts/BarChart";
import quickicon1 from "../../Assets/hotkeys_editprofile.png";
import quickicon2 from "../../Assets/hotkeys_advertise1.png";
import quickicon3 from "../../Assets/hotkeys_addphotos.png";
import quickicon4 from "../../Assets/hotkeys_addcontact.png";
import quickicon5 from "../../Assets/hotkeys_whatsapp.png";
import quickicon6 from "../../Assets/hotkeys_addbusinesstiming.png";
import quickicon7 from "../../Assets/hotkeys_editcatalogue.png";
import quickicon8 from "../../Assets/hotkeys_addoffer.png";
import quickicon9 from "../../Assets/hotkeys_replyreview.png";
import quickicon10 from "../../Assets/hotkeys_addwebsite.png";
import quickicon11 from "../../Assets/hotkeys_addvideo (1).png";
import quickicon12 from "../../Assets/hotkeys_addsociallink.png";
import quickicon13 from "../../Assets/biz_icon (2).png";
import leads from "../../Assets/hotkeys_addsociallink.png";
import tools from "../../Assets/business_name_icon.png";
import kyc from "../../Assets/bs_kyc_icon.png";
import info from "../../Assets/bs_businfo_icon.png";
import support from "../../Assets/bs_support_icon.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { calcBPScore } from "../../Contants";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

const MonthLeads = [400, 300, 200, 100, 50];
const xLabels = ["jan 2024", "feb 2024", "mar 2024", "apr 2024", "may 2024"];

const BusinesMainPage = () => {
  const selectedBusiness = useSelector(store => store.selectedBusiness);
  const businessProfile = useSelector(store => store.businessProfile);
  const [gaugeValue, setGaugeValue] = useState(30);
  const dispatch = useDispatch();
  console.log(selectedBusiness)

  useEffect(() => {
    let value = calcBPScore(businessProfile)
    setGaugeValue(value);
  }, [businessProfile]);
  console.log(businessProfile, selectedBusiness)
  useEffect(() => {
    const fetchBusinessProfile = () => {
      try {
        backendHost.get(`/businessProfile/getBusinessProfile/${selectedBusiness._id}`)
          .then(res => {
            console.log(res?.data?.businessProfile)
            dispatch(setBusinessProfile(res?.data?.businessProfile))
          })
          .catch(err => {
            console.log(err)
            dispatch(showToast({ type: 'error', message: err?.response?.data?.message }))
          })
      } catch (error) {
        dispatch(showToast({ type: 'error', message: error.message }));
      }
    };
    fetchBusinessProfile();
  }, [selectedBusiness, dispatch]);

  return (
    <div className="business-main-page">
      <div className="business-nav">
        <div className="container">
          <div className="business-title">
            <h3 className="business-name">
              <Link to="/allBusinesses">
                <ChevronLeftIcon />
              </Link>
            </h3>
            <h4 style={{ paddingLeft: "20px" }}>Maxtechword</h4>
          </div>

          <div className="business-content">
            <div className="business-score">
              <Card className="score-card">
                <div className="score-content">
                  <div className="score-gauge">
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      spacing={{ xs: 1, md: 3 }}
                    >
                      <Gauge width={100} height={100} value={gaugeValue} />
                    </Stack>
                  </div>
                  <div className="score-details">
                    <div className="score-heading">
                      Increase Business Profile Score
                    </div>
                    <p className="score-description">
                      Reach out to more customers
                    </p>
                  </div>
                </div>
                <div className="score-action">
                  <button className="action-button">INCREASE SCORE</button>
                </div>
              </Card>
            </div>

            <div className="quick-links">
              <h3>Quick Links</h3>
              <div className="container">
                <div className="row text-center">
                  {[
                    { src: quickicon1, text: "Edit Profile", link: `/businessDashboard/editBusinessProfile/${selectedBusiness?._id}` },
                    { src: quickicon2, text: "Advertis", link: "/businessDashboard/" },
                    { src: quickicon3, text: "Add Photos", link: `/businessDashboard/addPhoto/${selectedBusiness?._id}` },
                    { src: quickicon4, text: "Add Contact", link: "/businessDashboard/addContact" },
                    { src: quickicon5, text: "Add Whatsapp", link: "/businessDashboard/addWhatsApp" },
                    { src: quickicon6, text: "Business Timings", link: "/businessDashboard/updateBusinessTimings" },
                    { src: quickicon7, text: "Add Catalogue", link: "/businessDashboard/addCatalogue" },
                    { src: quickicon8, text: "Add Offer", link: "/businessDashboard/addOffers" },
                    { src: quickicon9, text: "Reviews", link: "/businessDashboard/reviews" },
                    { src: quickicon10, text: "Add Website", link: "/businessDashboard/addWebsite" },
                    { src: quickicon11, text: "Add Video", link: "/businessDashboard/addVideo" },
                    { src: quickicon12, text: "Add Social Links", link: "/businessDashboard/addSocialMediaLinks" },
                    { src: quickicon13, text: "Share Biz Card", link: "/businessDashboard/businessCard" },
                  ].map((item, index) => (
                    <div className="col-2 p-3" key={index}>
                      <Link to={item.link}>
                        <img
                          src={item.src}
                          alt=""
                          className="businessmain-quick"
                        />
                        <p className="businessmain-quick-text">{item.text}</p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="quick-links">
              <h3>My Business</h3>
              <div className="container">
                <div className="row mt-5">
                  {[
                    { src: quickicon1, title: "Leads", text: "View Leads, Competitor Treands & Analytics", link: "/" },
                    { src: leads, title: "Business Profile", text: "Update categories, whatapp / Mobile number & Business Timings", link: "/" },
                    { src: quickicon7, title: "Catalogue", text: "Showcase Product & Services", link: "/" },
                    { src: tools, title: "Business Tools", text: "Manage offers, Compaigns & Ratings", link: "/" },
                    { src: quickicon2, title: "Advertise", text: "Reach Out To 17 Croce New Customers", link: "/" },
                    { src: kyc, title: "KYC, Payment & Invoices", text: "Update KYC and GST Details", link: "/" },
                    { src: info, title: "Additional Business Info", text: "Update Services, Amenities, Awards And More", link: "/" },
                    { src: support, title: "Support", text: "Connect With Us", link: "/" },
                  ].map((item, index) => (
                    <div className="col-6 mt-4" key={index}>
                      <Link to={item.link}>
                        <Card className="d-flex justify-content-start align-items-center">
                          <div className="p-3">
                            <img src={item.src} alt="" height="40px" width="40px" />
                          </div>
                          <div className="p-3">
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                          </div>
                        </Card>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="leadstrends mt-5">
              <Card className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="text-dark">Lead Trends</h3>
                  </div>
                  <div>
                    <button type="submit" className="action-button">
                      View More Trends
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="leads-treads col-4">
                    <p className="pb-5">Leads Generated in your Category</p>
                    <h4 className="text-center text-dark">Total Leads Of 5 Month</h4>
                    <h4 className="text-center text-dark">1050</h4>
                  </div>
                  <div className="barchar col-8">
                    <BarChart
                      height={500}
                      width={500}
                      series={[{ data: MonthLeads, id: "LeadsId" }]}
                      xAxis={[{ data: xLabels, scaleType: "band" }]}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinesMainPage;
