import React from 'react';
import styles from './businessContactinfo.module.css';
import { useSelector } from 'react-redux';

const BusinessContactInformation = () => {
  const businessProfile = useSelector(store => store?.businessProfile)
  const selectedBusiness = useSelector(store => store?.selectedBusiness)
  return (
    <div className={styles.container}>
      <div className="container my-5">
        <h2 className={`mb-4 ${styles.heading}`}>Contact Information</h2>
        <div className="row">
          <div className="col-md-6">
            <iframe
              title="location-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.691701859632!2d-6.262275584356918!3d53.34410397997988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e9cf0b5f6d5%3A0x493635c7f1ec8e0c!2s1%20Grafton%20Street%2C%20Dublin%2C%20Ireland!5e0!3m2!1sen!2sus!4v1654700039535!5m2!1sen!2sus"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              className={styles.map}
            ></iframe>
          </div>
          <div className="col-md-6">
            {businessProfile?.timing?.days?.length > 0 &&
              <div className="row p-2">
                <div className="col-12">
                  <h5 className="row-h5">Business Timings:</h5>
                </div>
                <div className="col">
                  <table className="timing-table">
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Time Slots</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessProfile?.timing?.days?.map((day, index) => (
                        <tr key={day._id} className={index % 2 === 0 ? 'even' : 'odd'}>
                          <td>{day.name}</td>
                          <td>{day.timeSlots.join(', ')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-4">
            <h5 className={styles.h5bCI}>Address</h5>
            <p className={styles?.pbCI}>
              {selectedBusiness?.address},
              <br />
              {selectedBusiness?.city},
              <br />
              {selectedBusiness?.state}, {selectedBusiness?.country}
            </p>
          </div>
          <div className="col-md-4">
            <h5 className={styles.h5bCI}>Telephone</h5>
            <p className={styles?.pbCI}>
              Tel: +1 {businessProfile?.contact?.mobile1}
              <br />
              Mob: +1 {businessProfile?.contact?.mobile2}
            </p>
          </div>
          <div className="col-md-4">
            <h5 className={styles.h5bCI}>Email</h5>
            <p className={styles?.pbCI}>
              {businessProfile?.contact?.email ? businessProfile?.contact?.email : selectedBusiness?.businessEmail}
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <h5 className={styles.h5bCI}>Websites</h5>
            {businessProfile?.websites?.map((w, index) => (
              <p key={index} className={styles?.pbCI}>
                <a className={styles.businessCILink} target='_blank' href={w}>
                  {w}
                </a>
              </p>
            ))}

            {/* <a className={styles.businessCILink} href="https://aonetheme.com/service-finder">
                https://aonetheme.com/service-finder
              </a>
            </p>
            <p className={styles?.pbCI}>
              <a className={styles.businessCILink} href="https://aonetheme.com/service-finder">
                https://aonetheme.com/service-finder
              </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessContactInformation;
// import React from 'react';
// import styles from './businessContactinfo.module.css';
// import { useSelector } from 'react-redux';

// const BusinessContactInformation = () => {
//   const businessProfile = useSelector(store => store?.businessProfile)
//   const selectedBusiness = useSelector(store => store?.selectedBusiness)

//   // Function to construct the Google Maps URL with the formatted address
//   const constructMapUrl = () => {
//     if (!selectedBusiness) return '';
//     const { address, city, state, country } = selectedBusiness;
//     const formattedAddress = `${address}, ${city}, ${state}, ${country}`;
//     const encodedAddress = encodeURIComponent(formattedAddress);
//     return `https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=${encodedAddress}`;
//     // Replace YOUR_API_KEY with your actual Google Maps API key
//   }

//   return (
//     <div className={styles.container}>
//       <div className="container my-5">
//         <h2 className={`mb-4 ${styles.heading}`}>Contact Information</h2>
//         <div className="row">
//           <div className="col-md-6">
//             <iframe
//               title="location-map"
//               src={constructMapUrl()}
//               width="100%"
//               height="450"
//               style={{ border: 0 }}
//               allowFullScreen=""
//               loading="lazy"
//               className={styles.map}
//             ></iframe>
//           </div>
//           <div className="col-md-6">
//             {businessProfile?.timing?.days?.length > 0 &&
//               <div className="row p-2">
//                 <div className="col-12">
//                   <h5 className="row-h5">Business Timings:</h5>
//                 </div>
//                 <div className="col">
//                   <table className="timing-table">
//                     <thead>
//                       <tr>
//                         <th>Day</th>
//                         <th>Time Slots</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {businessProfile?.timing?.days?.map((day, index) => (
//                         <tr key={day._id} className={index % 2 === 0 ? 'even' : 'odd'}>
//                           <td>{day.name}</td>
//                           <td>{day.timeSlots.join(', ')}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             }
//           </div>
//         </div>
//         <div className="row mt-4">
//           <div className="col-md-4">
//             <h5 className={styles.h5bCI}>Address</h5>
//             <p className={styles?.pbCI}>
//               {selectedBusiness?.address},
//               <br />
//               {selectedBusiness?.city},
//               <br />
//               {selectedBusiness?.state}, {selectedBusiness?.country}
//             </p>
//           </div>
//           <div className="col-md-4">
//             <h5 className={styles.h5bCI}>Telephone</h5>
//             <p className={styles?.pbCI}>
//               Tel: +1 {businessProfile?.contact?.mobile1}
//               <br />
//               Mob: +1 {businessProfile?.contact?.mobile2}
//             </p>
//           </div>
//           <div className="col-md-4">
//             <h5 className={styles.h5bCI}>Email</h5>
//             <p className={styles?.pbCI}>
//               {businessProfile?.contact?.email ? businessProfile?.contact?.email : selectedBusiness?.businessEmail}
//             </p>
//           </div>
//         </div>
//         <div className="row mt-4">
//           <div className="col-md-12">
//             <h5 className={styles.h5bCI}>Websites</h5>
//             {businessProfile?.websites?.map((w, index) => (
//               <p key={index} className={styles?.pbCI}>
//                 <a className={styles.businessCILink} target='_blank' href={w}>
//                   {w}
//                 </a>
//               </p>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusinessContactInformation;
