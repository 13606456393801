import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import backendHost from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

const AddSocialMediaLinks = () => {
    const businessProfile = useSelector(store => store?.businessProfile);
    const selectedBusiness = useSelector(store => store?.selectedBusiness);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initialState = [
        { platform: "facebook", link: "" },
        { platform: "twitter", link: "" },
        { platform: "linkedin", link: "" },
        { platform: "youtube", link: "" },
        { platform: "instagram", link: "" }
    ];

    const [links, setLinks] = useState(businessProfile?.socialMediaLinks?.length > 0 ? businessProfile?.socialMediaLinks : initialState);
    const [errors, setErrors] = useState(initialState.map(() => false));

    useEffect(() => {
        if (businessProfile?.socialMediaLinks?.length > 0) {
            setLinks(businessProfile.socialMediaLinks);
            setErrors(businessProfile.socialMediaLinks.map(() => false));
        } else {
            setLinks(initialState);
            setErrors(initialState.map(() => false));
        }
    }, [businessProfile]);

    const handleLinkChange = (index, event) => {
        const newLinks = links.map((linkData, i) => i === index ? { ...linkData, link: event.target.value } : linkData);
        setLinks(newLinks);

        // Validate URL
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        const isValidUrl = event.target.value === '' || urlPattern.test(event.target.value);
        const newErrors = errors.map((error, i) => i === index ? !isValidUrl : error);
        setErrors(newErrors);
    };

    const handleSubmit = (event) => {
        try {
            event.preventDefault();
            let allValid = true;
            errors.forEach(error => {
                if (error) {
                    allValid = false;
                }
            });

            if (allValid) {
                console.log('Submitted links:', links);
                backendHost.post(`/businessProfile/addSocialMediaLinks/${selectedBusiness?._id}`, { links })
                    .then(res => {
                        dispatch(showToast({ message: res?.data?.message, type: 'success' }));
                        dispatch(setBusinessProfile(res?.data?.businessProfile));
                        navigate("/businessDashboard");
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(showToast({ message: err?.response?.data?.message, type: 'error' }));
                    });

            } else {
                dispatch(showToast({ message: "Please correct the invalid URLs.", type: 'warning' }));
            }
        } catch (error) {
            dispatch(showToast({ type: 'error', message: error?.message }))
        }
    };

    return (
        <div className="container">
            <div className="ms-5 me-5">
                <Card className="">
                    <div className="name-heding">
                        <div className="d-flex ">
                            <Link to="/businessDashboard">
                                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
                            </Link>
                            <h5 className="business-heding">Add Social Media Links</h5>
                        </div>
                    </div>
                    <div className="conten">
                        <p>Add social media links in the business profile to maximize audience involvement.</p>
                        <form onSubmit={handleSubmit}>
                            {links.map((linkData, index) => (
                                <div key={index} className="mb-3">
                                    <label className="form-label">
                                        {linkData.platform.charAt(0).toUpperCase() + linkData.platform.slice(1)} Business Page Link
                                    </label>
                                    <div className="input-group mb-2">
                                        <input
                                            type="url"
                                            className={`form-control ${errors[index] ? 'is-invalid' : ''}`}
                                            value={linkData.link}
                                            onChange={(e) => handleLinkChange(index, e)}
                                            placeholder={`Enter ${linkData.platform} link`}
                                        />
                                        {errors[index] && (
                                            <div className="invalid-feedback">
                                                Please enter a valid URL.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default AddSocialMediaLinks;
