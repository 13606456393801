import React from 'react';
import { useSelector } from 'react-redux';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const AboutProvider = () => {
    const businessProfile = useSelector(store => store?.businessProfile);
    const selectedBusiness = useSelector(store => store?.selectedBusiness);

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<p key={i}><StarBorderIcon /></p>);
        }
        return stars;
    };

    return (
        <div className="aboutprovider row">
            <div className="aboutprovider-img col-3">
                <div class=" aboutprovider-img-style">
                    <img
                        src={businessProfile?.photos[0]}
                        className="aboutprovider-img-pro"
                    />
                </div>

                <h5 className="text-center">{businessProfile?.photos?.length} photo(s)</h5>
                <div className="d-flex justify-content-center align-items-center">
                    {businessProfile?.reviews?.length > 0 &&
                        <>
                            {
                                renderStars(
                                    (
                                        businessProfile.reviews.reduce((sum, obj) => sum + obj.rating, 0) / businessProfile.reviews.length
                                    ).toFixed(0))
                            }
                        </>
                    }
                </div>
            </div>
            <div className="aboutprovider-details col-9">
                <h2>About Provider</h2>
                <p className="aboutprovider-details-text">
                    <span>Categories:</span> {selectedBusiness?.category}
                </p>
                <p className="aboutprovider-details-text">
                    {`We have a wide spectrum of expertise in web solutions within the ${selectedBusiness?.category} industry, giving us the necessary skills
                    and knowledge to help you increase your presence on the web. Through our expertise, technological knowledge, global presence, and bespoke web solutions, we can help you transform your business, maximize performance, and surpass the competition.`}
                </p>
                <p className="aboutprovider-details-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sed tempus tortor nec tellus sollicitudin lacinia id per
                    conubia nostra per inceptos.
                </p>

                <div className="aboutprovider-details-btn">
                    <button
                        type="submit"
                        className="aboutprovider-details-btn-1 btn btn-warning"
                    >
                        <BusinessCenterIcon />
                        Invite for Job
                    </button>
                    <button
                        type="submit"
                        className="aboutprovider-details-btn-1 btn btn-warning"
                    >
                        <FavoriteBorderIcon />
                        Add To Fav
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AboutProvider;
