import { createSlice } from "@reduxjs/toolkit";

const initialState = { all: [], filtered: [] };

const allBusinessesSlice = createSlice({
    name: "allBusinesses",
    initialState,
    reducers: {
        setAllBusinesses: (state, action) => {
            state.all = action.payload;
            state.filtered = action.payload;
        },
        filterBusinesses: (state, action) => {
            const filters = action.payload;
            const businesses = state.all;

            let filteredBusinesses = businesses.filter((business) => {
                const { category, city, subcategory, keyword } = filters;
                console.log(filters?.country === business?.country);
                return (
                    (!category || business.category === category) &&
                    (!city || business.city === city) &&
                    (!filters?.country ||
                        business?.country === filters?.country) && // Compare business country name with filter country name
                    (!filters?.state || business.state === filters?.state) &&
                    (!subcategory || business.subcategory === subcategory) &&
                    (!keyword ||
                        business.businessName
                            .toLowerCase()
                            .includes(keyword.toLowerCase()))
                );
            });

            state.filtered = filteredBusinesses;
            return state;
        },
        resetAllBusinesses: () => {
            return initialState;
        },
    },
});

export const { setAllBusinesses, resetAllBusinesses, filterBusinesses } =
    allBusinessesSlice.actions;

export default allBusinessesSlice.reducer;
