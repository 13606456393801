import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { hideToast } from '../../Store/Slices/toastSlice';
import './Toast.css'; // Import custom CSS for animations

const Toast = () => {
    const dispatch = useDispatch();
    const { show, message, type } = useSelector(store => store.toast);

    useEffect(() => {
        if (show) {
            const baseToastOptions = {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    width: '25%',
                    marginTop: '9.5vh',
                    position: 'fixed', // Ensure fixed position
                    top: '10px', // Adjust top position if necessary
                    right: '10px', // Adjust right position if necessary
                    zIndex: 9999 // Ensure it stays on top
                },
                transition: Slide,
            };

            let customStyles = {};
            switch (type) {
                case 'success':
                    customStyles = {
                        backgroundColor: '#d4edda', // Light green background
                        color: '#155724', // Dark green text
                    };
                    toast.success(message, { ...baseToastOptions, style: { ...baseToastOptions.style, ...customStyles } });
                    break;
                case 'error':
                    customStyles = {
                        backgroundColor: '#f8d7da', // Light red background
                        color: '#721c24' // Dark red text
                    };
                    toast.error(message, { ...baseToastOptions, style: { ...baseToastOptions.style, ...customStyles } });
                    break;
                case 'warning':
                    customStyles = {
                        backgroundColor: '#fff3cd', // Light yellow background
                        color: '#856404' // Dark yellow text
                    };
                    toast.warn(message, { ...baseToastOptions, style: { ...baseToastOptions.style, ...customStyles } });
                    break;
                case 'info':
                default:
                    customStyles = {
                        backgroundColor: '#d1ecf1', // Light blue background
                        color: '#0c5460' // Dark blue text
                    };
                    toast.info(message, { ...baseToastOptions, style: { ...baseToastOptions.style, ...customStyles } });
                    break;
            }

            dispatch(hideToast());
        }
    }, [show, message, type, dispatch]);

    return <ToastContainer />;
};

export default Toast;
