import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

const selectedBusinessSlice = createSlice({
    name: "selectedBusiness",
    initialState,
    reducers: {
        setSelectedBusiness: (state, action) => {
            return action.payload;
        },
        resetSelectedBusiness: () => {
            return initialState;
        },
    },
});

export const { setSelectedBusiness, resetSelectedBusiness } =
    selectedBusinessSlice.actions;
export default selectedBusinessSlice.reducer;
