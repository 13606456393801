
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../../Pages/Login/Login'
import Home from '../../Pages/Home/Home'
import Signup from "../../Pages/Login/Signup"
import Pages from "../../Pages/Pages/Pages"
import BusinessRegisterForm from '../../Pages/BusinessRegistraion/BusinessRegisterForm'
import Profile from "../../Pages/Profile/Profile"
import Jobs from "../Jobs/Jobs"
import Blogs from "../../Pages/Blogs/Blogs"
import Contact from "../../Pages/Contact/Contact"
import Success from "../../Pages/Success/Success"
import Cancel from "../../Pages/Cancel/Cancel"
import AllBusinesses from "../../Pages/AllBusinesses/AllBusinesses"
import BusinessDashboard from "../../Pages/BusinessDashboard/BusinessDashboard"
import EditBusinessProfile from "../BusinessDashboard/EditBusinessProfile"
import AddPhotos from "../BusinessDashboard/AddPhotos"
import AddContact from "../BusinessDashboard/AddContact"
import UppdateBusinessTimings from "../BusinessDashboard/UpdateBusinessTimings"
import AddCatalogue from "../BusinessDashboard/AddCatalogue"
import AddWebsite from "../BusinessDashboard/AddWebsite"
import AddOffers from "../BusinessDashboard/AddOffers"
import AddVideo from "../BusinessDashboard/AddVideo"
import AddSocialMediaLinks from "../BusinessDashboard/AddSocialMediaLinks"
import AddWhatsApp from "../BusinessDashboard/AddWhatsApp"
import Reviews from '../BusinessDashboard/Reviews'
import BusinessCard from '../BusinessDashboard/BusinessCard';
import BusinessInformation from '../../Pages/BusinessInformation/BusinessInformation'
import SearchBusinesses from '../../Pages/SearchBusinesses/SearchBusinesses'
import AboutBusiness from '../../Pages/AboutBusiness/AboutBusiness'
import CategoryDetails from "../../Pages/CategoryDetails/CategoryDetails"
import AddBookingPage from '../../Pages/AddBooking/AddBooking'
import ViewAllCategories from '../../Pages/ViewAllCategories/ViewAllCategories'

const AllRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/pages" element={<Pages />} />
            <Route
                exact
                path="/register"
                element={<BusinessRegisterForm type={"Add"} />}
            />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/jobs" element={<Jobs />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/success" element={<Success />} />
            <Route
                exact
                path="/buyPackage-cancel"
                element={<Cancel />}
            />
            <Route
                exact
                path="/allBusinesses"
                element={<AllBusinesses />}
            />
            <Route
                exact
                path="/businessDashboard"
                element={<BusinessDashboard />}
            />
            <Route exact path="/businessInformation" element={<BusinessInformation />} />
            <Route
                exact
                path="/businessDashboard/editBusinessProfile/:id"
                element={<EditBusinessProfile />}
            />
            <Route exact path="/businessDashboard/addPhoto/:id" element={<AddPhotos />} />
            <Route exact path="/businessDashboard/addContact" element={<AddContact />} />
            <Route
                exact
                path="/businessDashboard/updateBusinessTimings"
                element={<UppdateBusinessTimings />}
            />
            <Route
                exact
                path="/businessDashboard/addCatalogue"
                element={<AddCatalogue />}
            />
            <Route exact path="/businessDashboard/addOffers" element={<AddOffers />} />
            <Route exact path="/businessDashboard/addVideo" element={<AddVideo />} />
            <Route
                exact
                path="/businessDashboard/addSocialMediaLinks"
                element={<AddSocialMediaLinks />}
            />
            <Route exact path="/businessDashboard/addWebsite" element={<AddWebsite />} />
            {/* <Route exact path="/addCat" element={<AddCat />} /> */}
            <Route
                exact
                path="/businessDashboard/addWhatsApp"
                element={<AddWhatsApp />}
            />
            <Route exact path='/businessDashboard/reviews' element={<Reviews />}
            />

            <Route exact path="/businessDashboard/businessCard" element={<BusinessCard />} />
            <Route exact path="/searchBusiness" element={<SearchBusinesses />} />
            <Route exact path="/business/:id" element={<AboutBusiness />} />
            <Route exact path="/categoryDetails/:id" element={<CategoryDetails />} />
            <Route exact path="/addBooking/:id" element={<AddBookingPage />} />
            <Route exact path="/viewCategories" element={<ViewAllCategories />} />
            {/* <Route exact path="/test" element={<Test  />} /> */}
        </Routes>
    )
}

export default AllRoutes