import React from "react";
import "./Work.css";
import tack1 from "../../../Assets/1.png";
import tack2 from "../../../Assets/2.png";
import tack3 from "../../../Assets/3.png";

function Work() {
  return (
    <div className="container">
      <div className="work p-2">
        <div className="work-stpes p-5">
          <p className="work-ste-1">STEPS</p>
          <h2 className="work-ste-2 ">How It Work</h2>
        </div>
        <div className="row justify-content-center ">
          <div className="col-lg-4 col-md-6">
            <div className="col-01">
              <div className="tack">01</div>
              <div className="tack-icon">
                <div className="aon-icon-1 d-flex align-items-center justify-content-center">
                  <img className="icon-img" src={tack1} alt="" />
                </div>
              </div>
              <div className="tack-icon-text">
                <h4 className="aon-tilte">Describe Your Task</h4>
                <p>This helps us determine which Taskers We are abest jobs.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="col-01">
              <div className="tack">02</div>
              <div className="tack-icon">
                <div className="aon-icon-1 d-flex align-items-center justify-content-center">
                  <img className="icon-img" src={tack2} alt="" />
                </div>
              </div>
              <div className="tack-icon-text">
                <h4 className="aon-tilte">Choose a Taskerk</h4>
                <p>This helps us determine which Taskers We are abest jobs.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="col-01">
              <div className="tack">03</div>
              <div className="tack-icon">
                <div className="aon-icon-1 d-flex align-items-center justify-content-center">
                  <img className="icon-img" src={tack3} alt="" />
                </div>
              </div>
              <div className="tack-icon-text">
                <h4 className="aon-tilte">Live Smarter</h4>
                <p>This helps us determine which Taskers We are abest jobs.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
