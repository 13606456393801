import React from "react";
import "./Sec1.css";
import pro1 from "../../../Assets/1 (1).png"
import pro2 from "../../../Assets/2 (1).png"
import pro3 from "../../../Assets/3 (1).png"

function Sec1() {
  return (
    <div className="choose">
      <section class="aon-why-choose2-area">
        <div class="container">
          <div class="aon-why-choose2-box">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="aon-why-choose-info">
                  <div class="section-head">
                    <span class="aon-sub-title">Choose</span>
                    <h2 class="aon-title">Why Choose us</h2>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p> */}
                  </div>

                  <ul class="aon-why-choose-steps list-unstyled">
                    <li class="d-flex p-4">
                      <div class="aon-w-choose-left aon-icon-effect">
                        <div class="aon-w-choose-icon">
                          <i class="aon-icon">
                            <img src={pro1} alt="" className="aon-icon-12" />
                          </i>
                        </div>
                      </div>
                      <div class="aon-w-choose-right">
                        <h4 class="aon-title">Meet new customers</h4>
                        <p>
                          Suspendisse tincidunt rutrum ante. Vestibulum
                          elementum ipsum sit amet turpis elementum lobortis.
                        </p>
                      </div>
                    </li>
                    <li class="d-flex p-4">
                      <div class="aon-w-choose-left aon-icon-effect">
                        <div class="aon-w-choose-icon">
                          <i class="aon-icon">
                            <img src={pro2} alt="" className="aon-icon-12" />
                          </i>
                        </div>
                      </div>
                      <div class="aon-w-choose-right">
                        <h4 class="aon-title">Grow your revenue</h4>
                        <p>
                          Suspendisse tincidunt rutrum ante. Vestibulum
                          elementum ipsum sit amet turpis elementum lobortis.
                        </p>
                      </div>
                    </li>
                    <li class="d-flex p-4">
                      <div class="aon-w-choose-left aon-icon-effect">
                        <div class="aon-w-choose-icon">
                          <i class="aon-icon">
                            <img src={pro3} alt="" className="aon-icon-12" />
                          </i>
                        </div>
                      </div>
                      <div class="aon-w-choose-right">
                        <h4 class="aon-title">Build your online reputation</h4>
                        <p>
                          Suspendisse tincidunt rutrum ante. Vestibulum
                          elementum ipsum sit amet turpis elementum lobortis.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="aon-why-choose2-line">
                  <div class="aon-why-choose2-pic"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sec1;
