import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const categoriesSlice = createSlice({
    initialState,
    name: "categories",
    reducers: {
        setCategories: (_, { payload }) => payload,
        resetCategories: () => initialState,
    },
});

export const { setCategories, resetCategories } = categoriesSlice.actions;

export default categoriesSlice?.reducer;
