import React, { useState } from "react";
import "./AddPhotos.css";
import { Card } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import backendHost from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Store/Slices/toastSlice";

function AddPhotos() {
  const selectedBusiness = useSelector(store => store.selectedBusiness);
  const businessProfile = useSelector(store => store.businessProfile);
  const [photos, setPhotos] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [previousPhotos, setPreviousPhotos] = useState(businessProfile?.photos);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      photos.forEach(photo => {
        formData.append("businessPhotos", photo);
      });
      backendHost.post(`/businessProfile/addPhoto/${selectedBusiness._id}`, formData)
        .then(res => {
          navigate("/businessDashboard");
          setPhotos([]);
          setPreviews([]);
          dispatch(showToast({ type: 'success', message: res?.data?.message }))
        })
        .catch(err => { console.log(err?.message); dispatch(showToast({ type: 'danger', message: err?.response?.data?.message })) });
    } catch (error) {
      console.log(error);
      dispatch(showToast({ type: 'danger', message: error?.message }))
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setPhotos(prevPhotos => [...prevPhotos, ...selectedFiles]);

    // Create previews of the selected images
    const newPreviews = selectedFiles.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise(resolve => {
        reader.onloadend = () => resolve(reader.result);
      });
    });

    Promise.all(newPreviews).then(images => {
      setPreviews(prevPreviews => [...prevPreviews, ...images]);
    });
  };

  return (
    <div className="container">
      <div className="ms-5 me-5">
        <Card className="">
          <div className="name-heding">
            <div className="d-flex ">
              <Link to="/businessDashboard">
                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
              </Link>
              <h5 className="business-heding">Photos Gallery</h5>
            </div>
          </div>
          <form className="conten" onSubmit={handleSubmit}>
            <span>
              Make your business look more trustworthy by uploading photos and
              videos of your business premises.
            </span>
            <span>Recommended Size: 1000 px X 1000 px</span>
            <div className="addPhotoLabelDiv">
              <label
                htmlFor="file-upload"
                id="file-drag"
                className="text-center mt-5 addPhotoLabel"
              >
                <input
                  id="file-upload"
                  type="file"
                  name="fileUpload"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                  className="d-none"
                />
                <div id="start addPhotosDiv">
                  <i className="fa fa-download" aria-hidden="true"></i>
                  <div>Select files or drag here</div>
                  <div id="notimage" className="hidden">
                    Please select an image
                  </div>
                  <button
                    type="button"
                    id="file-upload-btn"
                    className="btn btn-primary mt-2"
                    onClick={() => document.getElementById("file-upload").click()}
                  >
                    Upload photos
                  </button>
                </div>
              </label>
            </div>
            {/* Image previews */}
            {previousPhotos?.length > 0 &&
              <h4>Previously Uploaded Files : </h4>
            }
            {previousPhotos?.length > 0 && (
              <div className="previousPhotos d-flex m-2">
                {previousPhotos?.map((previousPhoto, index) => (
                  <div key={index} className="preview-image">
                    <img
                      src={previousPhoto}
                      alt={`Preview ${index + 1}`}
                      style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}
                    />
                  </div>
                ))}
              </div>
            )}
            {previews.length > 0 && <h4>Selected Files : </h4>}
            {previews.length > 0 && (
              <div className="previews d-flex m-2">
                {previews.map((preview, index) => (
                  <div key={index} className="preview-image">
                    <img
                      src={preview}
                      alt={`Preview ${index + 1}`}
                      style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center pt-2 pb-2">
              <button
                type="submit"
                className="btn btn-warning text-white"
                style={{ width: "200px" }}
              >
                SAVE
              </button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default AddPhotos;
