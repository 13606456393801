import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import businesslogo from "../../Assets/1 (1).jpg"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Gauge } from "@mui/x-charts/Gauge";
import { useDispatch, useSelector } from "react-redux";
import backendHost from "../../Api";
import { setUser } from "../../Store/Slices/userSlice";
import { calcBPScore } from "../../Contants";
import { setSelectedBusiness } from "../../Store/Slices/selectedBusinessSlice";
import { setBusinessProfiles } from "../../Store/Slices/businessProfilesSlice";
import { showToast } from "../../Store/Slices/toastSlice";

function MybusinessPages() {
  const [gaugeValues, setGaugeValues] = useState([]);
  const location = useLocation();
  const userName = location.state?.userName;
  const plan = location.state?.plan;
  const navigate = useNavigate();
  const user = useSelector(store => store?.user);
  const businessProfiles = useSelector(store => store?.businessProfiles);
  const dispatch = useDispatch();

  async function handleBusiness() {
    navigate("/register", { state: { userName, plan } });
  }

  useEffect(() => {
    if (!user?._id) {
      navigate("/");
    } else {
      try {
        backendHost.get(`/business/getBusiness/${user?._id}`)
          .then(res => {
            console.log(res?.data?.businessProfiles);
            dispatch(setUser(res?.data?.user));
            dispatch(setBusinessProfiles(res?.data?.businessProfiles))
            calculateGaugeValues(res?.data?.user?.business);
          })
          .catch(err => console.log(err?.message));
      } catch (error) {
        dispatch(showToast({ message: error?.message, type: 'error' }))
        console.log(error?.message);
      }
    }
  }, [user?._id]);

  const calculateGaugeValues = (businesses) => {
    const totalFields = 19; // Adjust this number based on your business object
    const newGaugeValues = businesses.map(business => {
      let filledFields = 0;
      if (business.businessName) filledFields++;
      if (business.city) filledFields++;
      if (business.businessLogo) filledFields++;
      if (business.businessEmail) filledFields++;
      if (business.address) filledFields++;
      if (business.businessNumber) filledFields++;
      if (business.category) filledFields++;
      if (business.country) filledFields++;
      if (business.email) filledFields++;
      if (business.language) filledFields++;
      if (business.ownerFirstName) filledFields++;
      if (business.ownerId) filledFields++;
      if (business.ownerLastName) filledFields++;
      if (business.ownerNumber) filledFields++;
      if (business.pin) filledFields++;
      if (business.plan) filledFields++;
      if (business.state) filledFields++;
      if (business.subCategory) filledFields++;
      if (business.userName) filledFields++;
      // Add checks for other fields...

      return Math.floor((filledFields / totalFields) * 100);
    });
    setGaugeValues(newGaugeValues);
  };
  return (
    <div>
      <div className="w-0 p-3 pt-3 pb-0 allBusinessParent">
        <Link
          to={"/"}
          className="m-10 text-left d-flex align-items-center text-decoration-none"
        >
          <ArrowBackIcon className="me-0" />
          Back to home
        </Link>
      </div>
      <div style={{ marginTop: "8px" }}>
        <Container>
          <div className="d-flex justify-content-between m-3">
            <Typography variant="h4" gutterBottom>
              My Business
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBusiness}
            >
              ADD More Business
            </Button>
          </div>
          <div >
            {user?.business?.map((b, index) => (
              <Card key={index} onClick={() => {
                dispatch(setSelectedBusiness(b))
                navigate("/businessInformation")
              }} className="d-flex justify-content-between align-items-center p-3">
                <div className="">
                  <CardContent>
                    <div className="d-flex align-items-center justify-content-center">
                      <CardMedia
                        component="img"
                        image={b?.businessLogo ? b?.businessLogo : businesslogo}
                        alt="businesslogo"
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "10px",
                          marginTop: "10px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.9)" // Shadow effect added here
                        }}
                      />
                      <div style={{ marginLeft: "16px" }}>
                        <Typography variant="h4">{b?.businessName}</Typography>
                        <Typography variant="body1">{b?.city}</Typography>
                        <div style={{ marginTop: "13px" }}>
                          <Button variant="contained" color="primary">
                            Advertise Now
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: "8px" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              dispatch(setSelectedBusiness(b))
                              navigate("/businessDashboard")
                            }}
                          >
                            Edit Business Profile
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </div>
                <Typography
                  variant="h4"
                  className="d-flex justify-content-between align-items-center "
                >
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 1, md: 3 }}
                  >
                    <Gauge width={100} height={100} value={calcBPScore(businessProfiles?.filter(bp => bp?.bid === b?._id)[0])} />
                  </Stack>
                </Typography>
              </Card>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default MybusinessPages;
