import React, { useState } from "react";
import "./AddVideo.css";
import { Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import backendHost from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessProfile } from "../../Store/Slices/businessProfileSlice";
import { showToast } from "../../Store/Slices/toastSlice";

const AddVideo = () => {
  const businessProfile = useSelector((store) => store?.businessProfile);
  const selectedBusiness = useSelector((store) => store?.selectedBusiness);
  const [videoUrl, setVideoUrl] = useState("");
  const [preview, setPreview] = useState(null);
  const [prevpreview, setprevPreview] = useState(businessProfile?.videoUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      backendHost
        .post("/businessProfile/addVideo/" + selectedBusiness?._id, { videoUrl })
        .then((res) => {
          dispatch(showToast({ message: res?.data?.message, type: 'success' }));
          dispatch(setBusinessProfile(res?.data?.businessProfile));
          navigate("/businessDashboard");
        })
        .catch((err) => {
          console.log(err)
          dispatch(showToast({ type: 'error', message: err?.response?.data?.message }))
        });
    } catch (error) {
      console.log(error);
      dispatch(showToast({ type: 'error', message: error?.message }))
    }
  };

  const handleUrlChange = (e) => {
    setVideoUrl(e.target.value);
    setPreview(e.target.value);
  };

  const handleVideoLoadedMetadata = (e) => {
    const duration = e.target.duration;
    if (duration > 120) {
      dispatch(showToast({ message: "Video should not be more than 2 minutes.", type: 'warning' }));
      setPreview(null);
      setVideoUrl("");
    }
  };

  return (
    <div className="container">
      <div className="ms-5 me-5">
        <Card className="">
          <div className="name-heding">
            <div className="d-flex">
              <Link to="/businessDashboard">
                <ArrowLeftIcon className="mt-1 fs-1 text-dark" />
              </Link>
              <h5 className="business-heding">Add Video</h5>
            </div>
          </div>
          <div className="content">
            <form id="file-upload-forms" className="uploader" onSubmit={handleSubmit}>
              <input type="submit" className="hidden" id="fs" />
            </form>
          </div>
          <div className="mt-0 mb-0">
            <Card className="m-5 mt-0">
              <div className="d-flex justify-content-center p-4">
                <div className="p-4 pt-0 w-100 d-flex w-full align-items-center justify-content-center">
                  <input
                    type="text"
                    placeholder="Enter Video Url here"
                    className="form-control rounded-3 w-50 p-2"
                    onChange={handleUrlChange}
                    value={videoUrl}
                  />
                </div>
              </div>
            </Card>
          </div>
          {prevpreview &&
            <h4><center>Previous VideoUrl's Preview</center></h4>
          }
          {prevpreview && (
            <div className="d-flex justify-content-center mt-0">
              <video
                controls
                autoPlay
                muted
                loop
                className="video-preview"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
                }}
                onLoadedMetadata={handleVideoLoadedMetadata}
              >
                <source src={prevpreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {preview &&
            <h4><center>Current Video Preview</center></h4>
          }
          {preview && (
            <div className="d-flex justify-content-center mt-0">
              <video
                controls
                autoPlay
                muted
                loop
                className="video-preview"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
                }}
                onLoadedMetadata={handleVideoLoadedMetadata}
              >
                <source src={preview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center pt-0 pb-2" style={{ marginTop: 0 }}>
            <button
              type="button"
              className="btn btn-warning text-white mb-3"
              style={{ width: "200px", marginTop: "10px" }}
              onClick={() => {
                let a = document.getElementById("fs");
                a.click();
              }}
            >
              SAVE
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddVideo;
