import React from 'react'
import { useSelector } from 'react-redux'

const AboutBusinessSocialMedia = () => {
    const businessProfile = useSelector(store => store?.businessProfile)
    return (
        <div className="">
            <div className="d-flex justify-content-end mt-4">
                <h4 className="text-center p-2">
                    Explore Us On Social Media
                </h4>
                {businessProfile?.socialMediaLinks?.map((obj, index) => (
                    <>
                        {obj?.platform === "facebook" && (
                            <a key={index} href={obj?.link}>
                                <i className="fab fa-facebook-square fa-2x me-3"></i>
                            </a>
                        )}
                        {obj?.platform === "twitter" && (
                            <a href={obj?.link}>
                                <i className="fab fa-twitter-square fa-2x me-3"></i>
                            </a>
                        )}
                        {obj?.platform === "linkedin" && (
                            <a href={obj?.link}>
                                <i className="fab fa-linkedin fa-2x me-3"></i>
                            </a>
                        )}
                        {obj?.platform === "youtube" && (
                            <a key={index} href={obj?.link}>
                                <i className="fab fa-youtube-square fa-2x me-3"></i>
                            </a>
                        )}
                        {obj?.platform === "instagram" && (
                            <a key={index} href={obj?.link}>
                                <i className="fab fa-instagram-square fa-2x me-3"></i>
                            </a>
                        )}
                    </>
                ))}
            </div>
        </div>
    )
}

export default AboutBusinessSocialMedia